import { default as indexTL50S0xoIpMeta } from "/usr/src/webshop/pages/index.vue?macro=true";
import { default as about_45usmU71aRvcExMeta } from "/usr/src/webshop/pages/about-us.vue?macro=true";
import { default as callbackTyUxbO3sXKMeta } from "/usr/src/webshop/pages/account/login/callback.vue?macro=true";
import { default as indexKNap8rmoJEMeta } from "/usr/src/webshop/pages/account/login/index.vue?macro=true";
import { default as _91slug_93zHy9E8P4kXMeta } from "/usr/src/webshop/pages/account/order/[slug].vue?macro=true";
import { default as overview6S6UcIkD9kMeta } from "/usr/src/webshop/pages/account/overview.vue?macro=true";
import { default as recovery53mECIUVKPMeta } from "/usr/src/webshop/pages/account/recovery.vue?macro=true";
import { default as reset_45passwordcdZ80Fko61Meta } from "/usr/src/webshop/pages/account/reset-password.vue?macro=true";
import { default as adyen_45paymentQ79ZCNRwXBMeta } from "/usr/src/webshop/pages/adyen-payment.vue?macro=true";
import { default as _91slug_93eEDSkUBoTAMeta } from "/usr/src/webshop/pages/author/[slug].vue?macro=true";
import { default as index9AOP056g8xMeta } from "/usr/src/webshop/pages/author/index.vue?macro=true";
import { default as _91slug_93oThgs0MTeaMeta } from "/usr/src/webshop/pages/blog/[slug].vue?macro=true";
import { default as booklet_45labels_45design_45detailsCaI536S7avMeta } from "/usr/src/webshop/pages/booklet-labels-design-details.vue?macro=true";
import { default as booklet_45labels_45form4xxCsD0gjZMeta } from "/usr/src/webshop/pages/booklet-labels-form.vue?macro=true";
import { default as _91slug_93q6mH35oQJGMeta } from "/usr/src/webshop/pages/category/[slug].vue?macro=true";
import { default as indexlf0bE1W4kIMeta } from "/usr/src/webshop/pages/category/index.vue?macro=true";
import { default as clear_45cache_45all7MaRVKwKs9Meta } from "/usr/src/webshop/pages/clear-cache-all.vue?macro=true";
import { default as consultantsUS9B4Vl7L7Meta } from "/usr/src/webshop/pages/consultants.vue?macro=true";
import { default as contactHxomGztE4QMeta } from "/usr/src/webshop/pages/contact.vue?macro=true";
import { default as _91slug_93a7AbIdmEQpMeta } from "/usr/src/webshop/pages/content/[slug].vue?macro=true";
import { default as custom_45made_45shipping_45boxes_45formgCirt8vucYMeta } from "/usr/src/webshop/pages/custom-made-shipping-boxes-form.vue?macro=true";
import { default as _91slug_93RLpY3YbddOMeta } from "/usr/src/webshop/pages/data-check/[slug].vue?macro=true";
import { default as indexAubJnXmWUPMeta } from "/usr/src/webshop/pages/data-check/index.vue?macro=true";
import { default as deliveryyxkY4RZzldMeta } from "/usr/src/webshop/pages/delivery.vue?macro=true";
import { default as dictionaryusXI9VIn8wMeta } from "/usr/src/webshop/pages/dictionary.vue?macro=true";
import { default as digiboxyPJTG21D8LMeta } from "/usr/src/webshop/pages/digibox.vue?macro=true";
import { default as _91slug_93WEsvOGNwk4Meta } from "/usr/src/webshop/pages/faq/[slug].vue?macro=true";
import { default as index5hW08pKmg9Meta } from "/usr/src/webshop/pages/faq/index.vue?macro=true";
import { default as folding_45boxes_45formQzFineVXRWMeta } from "/usr/src/webshop/pages/folding-boxes-form.vue?macro=true";
import { default as food_45labels_45informationsBpJhdsdahoMeta } from "/usr/src/webshop/pages/food-labels-informations.vue?macro=true";
import { default as healthzmq4s5YxpPOMeta } from "/usr/src/webshop/pages/healthz.vue?macro=true";
import { default as impressumvxN7EZ7dlsMeta } from "/usr/src/webshop/pages/impressum.vue?macro=true";
import { default as _91slug_93bR1a6iu5ugMeta } from "/usr/src/webshop/pages/jobs/[slug].vue?macro=true";
import { default as indexlTzws98g0aMeta } from "/usr/src/webshop/pages/jobs/index.vue?macro=true";
import { default as laminate_45tubes_45formUKwcMmr0EGMeta } from "/usr/src/webshop/pages/laminate-tubes-form.vue?macro=true";
import { default as machinesVeXazlSxTyMeta } from "/usr/src/webshop/pages/machines.vue?macro=true";
import { default as _91slug_93fzgGhaud8PMeta } from "/usr/src/webshop/pages/magazine/[slug].vue?macro=true";
import { default as indexIfH8CgOMpuMeta } from "/usr/src/webshop/pages/magazine/index.vue?macro=true";
import { default as major_45customers_45contactQ37ZPONBD8Meta } from "/usr/src/webshop/pages/major-customers-contact.vue?macro=true";
import { default as major_45customerscpQ7Z3YH2sMeta } from "/usr/src/webshop/pages/major-customers.vue?macro=true";
import { default as _91slug_93dpFQ9Uko3kMeta } from "/usr/src/webshop/pages/materials/[slug].vue?macro=true";
import { default as index2YHJwghYq8Meta } from "/usr/src/webshop/pages/materials/index.vue?macro=true";
import { default as mellerudgG5o4pwLWlMeta } from "/usr/src/webshop/pages/mellerud.vue?macro=true";
import { default as mymueslikGUV7ALafiMeta } from "/usr/src/webshop/pages/mymuesli.vue?macro=true";
import { default as mymusliklQxaYiXkXMeta } from "/usr/src/webshop/pages/mymusli.vue?macro=true";
import { default as newsletter_45confirmationLwkJHJjyJyMeta } from "/usr/src/webshop/pages/newsletter-confirmation.vue?macro=true";
import { default as addressRRdvIUxF45Meta } from "/usr/src/webshop/pages/order/address.vue?macro=true";
import { default as cardjUA5ZTsqEKMeta } from "/usr/src/webshop/pages/order/card.vue?macro=true";
import { default as login0VG7pLfhqfMeta } from "/usr/src/webshop/pages/order/login.vue?macro=true";
import { default as overviewDpi4KRAgfiMeta } from "/usr/src/webshop/pages/order/overview.vue?macro=true";
import { default as printdatawCBp4soi4sMeta } from "/usr/src/webshop/pages/order/printdata.vue?macro=true";
import { default as shipping8QRsAaIan2Meta } from "/usr/src/webshop/pages/order/shipping.vue?macro=true";
import { default as thank_45youw64uWVYufPMeta } from "/usr/src/webshop/pages/order/thank-you.vue?macro=true";
import { default as our_45promiseQiurQDHWlIMeta } from "/usr/src/webshop/pages/our-promise.vue?macro=true";
import { default as package_45leaflets_45package_45inserts_45formEt80PNDmspMeta } from "/usr/src/webshop/pages/package-leaflets-package-inserts-form.vue?macro=true";
import { default as paypal_listeneryOeCsNs1r8Meta } from "/usr/src/webshop/pages/paypal/paypal_listener.vue?macro=true";
import { default as index41xcvZ4TrPMeta } from "/usr/src/webshop/pages/print-data/index.vue?macro=true";
import { default as repairknkkhZuJPqMeta } from "/usr/src/webshop/pages/print-data/repair.vue?macro=true";
import { default as printed_45labels_45formJKMDMMt0yFMeta } from "/usr/src/webshop/pages/printed-labels-form.vue?macro=true";
import { default as privacy_45policy2WYyyO2BhYMeta } from "/usr/src/webshop/pages/privacy-policy.vue?macro=true";
import { default as _91slug_93UhSj1oq5uGMeta } from "/usr/src/webshop/pages/products/[slug].vue?macro=true";
import { default as indexBALeFK7cDEMeta } from "/usr/src/webshop/pages/products/index.vue?macro=true";
import { default as redirect_45to_45paypal2SSWCgGFSdMeta } from "/usr/src/webshop/pages/redirect-to-paypal.vue?macro=true";
import { default as sample_45corrugated_45cardboardbq31GwdGTVMeta } from "/usr/src/webshop/pages/sample-corrugated-cardboard.vue?macro=true";
import { default as sampleVA9t0Y69h6Meta } from "/usr/src/webshop/pages/sample.vue?macro=true";
import { default as sampletube9hKvUuyhbuMeta } from "/usr/src/webshop/pages/sampletube.vue?macro=true";
import { default as sitemapQ4FiwpGrEUMeta } from "/usr/src/webshop/pages/sitemap.vue?macro=true";
import { default as sustainabilityv05wKT300EMeta } from "/usr/src/webshop/pages/sustainability.vue?macro=true";
import { default as swiss_45fairWuCOb43gXvMeta } from "/usr/src/webshop/pages/swiss-fair.vue?macro=true";
import { default as terms_45of_45servicekkgjABgitLMeta } from "/usr/src/webshop/pages/terms-of-service.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/webshop/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "about-us___de",
    path: "/de/about-us",
    component: () => import("/usr/src/webshop/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "about-us___en",
    path: "/en/about-us",
    component: () => import("/usr/src/webshop/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "about-us___fr",
    path: "/fr/about-us",
    component: () => import("/usr/src/webshop/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "about-us___cs",
    path: "/cs/about-us",
    component: () => import("/usr/src/webshop/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "about-us___it",
    path: "/it/about-us",
    component: () => import("/usr/src/webshop/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "about-us___pl",
    path: "/pl/about-us",
    component: () => import("/usr/src/webshop/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "account-login-callback___de",
    path: "/de/account/login/callback",
    component: () => import("/usr/src/webshop/pages/account/login/callback.vue").then(m => m.default || m)
  },
  {
    name: "account-login-callback___en",
    path: "/en/account/login/callback",
    component: () => import("/usr/src/webshop/pages/account/login/callback.vue").then(m => m.default || m)
  },
  {
    name: "account-login-callback___fr",
    path: "/fr/account/login/callback",
    component: () => import("/usr/src/webshop/pages/account/login/callback.vue").then(m => m.default || m)
  },
  {
    name: "account-login-callback___cs",
    path: "/cs/account/login/callback",
    component: () => import("/usr/src/webshop/pages/account/login/callback.vue").then(m => m.default || m)
  },
  {
    name: "account-login-callback___it",
    path: "/it/account/login/callback",
    component: () => import("/usr/src/webshop/pages/account/login/callback.vue").then(m => m.default || m)
  },
  {
    name: "account-login-callback___pl",
    path: "/pl/account/login/callback",
    component: () => import("/usr/src/webshop/pages/account/login/callback.vue").then(m => m.default || m)
  },
  {
    name: "account-login___de",
    path: "/de/account/login",
    component: () => import("/usr/src/webshop/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___en",
    path: "/en/account/login",
    component: () => import("/usr/src/webshop/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___fr",
    path: "/fr/account/login",
    component: () => import("/usr/src/webshop/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___cs",
    path: "/cs/account/login",
    component: () => import("/usr/src/webshop/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___it",
    path: "/it/account/login",
    component: () => import("/usr/src/webshop/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___pl",
    path: "/pl/account/login",
    component: () => import("/usr/src/webshop/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-order-slug___de",
    path: "/de/account/order/:slug()",
    meta: _91slug_93zHy9E8P4kXMeta || {},
    component: () => import("/usr/src/webshop/pages/account/order/[slug].vue").then(m => m.default || m)
  },
  {
    name: "account-order-slug___en",
    path: "/en/account/order/:slug()",
    meta: _91slug_93zHy9E8P4kXMeta || {},
    component: () => import("/usr/src/webshop/pages/account/order/[slug].vue").then(m => m.default || m)
  },
  {
    name: "account-order-slug___fr",
    path: "/fr/account/order/:slug()",
    meta: _91slug_93zHy9E8P4kXMeta || {},
    component: () => import("/usr/src/webshop/pages/account/order/[slug].vue").then(m => m.default || m)
  },
  {
    name: "account-order-slug___cs",
    path: "/cs/account/order/:slug()",
    meta: _91slug_93zHy9E8P4kXMeta || {},
    component: () => import("/usr/src/webshop/pages/account/order/[slug].vue").then(m => m.default || m)
  },
  {
    name: "account-order-slug___it",
    path: "/it/account/order/:slug()",
    meta: _91slug_93zHy9E8P4kXMeta || {},
    component: () => import("/usr/src/webshop/pages/account/order/[slug].vue").then(m => m.default || m)
  },
  {
    name: "account-order-slug___pl",
    path: "/pl/account/order/:slug()",
    meta: _91slug_93zHy9E8P4kXMeta || {},
    component: () => import("/usr/src/webshop/pages/account/order/[slug].vue").then(m => m.default || m)
  },
  {
    name: "account-overview___de",
    path: "/de/account/overview",
    meta: overview6S6UcIkD9kMeta || {},
    component: () => import("/usr/src/webshop/pages/account/overview.vue").then(m => m.default || m)
  },
  {
    name: "account-overview___en",
    path: "/en/account/overview",
    meta: overview6S6UcIkD9kMeta || {},
    component: () => import("/usr/src/webshop/pages/account/overview.vue").then(m => m.default || m)
  },
  {
    name: "account-overview___fr",
    path: "/fr/account/overview",
    meta: overview6S6UcIkD9kMeta || {},
    component: () => import("/usr/src/webshop/pages/account/overview.vue").then(m => m.default || m)
  },
  {
    name: "account-overview___cs",
    path: "/cs/account/overview",
    meta: overview6S6UcIkD9kMeta || {},
    component: () => import("/usr/src/webshop/pages/account/overview.vue").then(m => m.default || m)
  },
  {
    name: "account-overview___it",
    path: "/it/account/overview",
    meta: overview6S6UcIkD9kMeta || {},
    component: () => import("/usr/src/webshop/pages/account/overview.vue").then(m => m.default || m)
  },
  {
    name: "account-overview___pl",
    path: "/pl/account/overview",
    meta: overview6S6UcIkD9kMeta || {},
    component: () => import("/usr/src/webshop/pages/account/overview.vue").then(m => m.default || m)
  },
  {
    name: "account-recovery___de",
    path: "/de/account/recovery",
    component: () => import("/usr/src/webshop/pages/account/recovery.vue").then(m => m.default || m)
  },
  {
    name: "account-recovery___en",
    path: "/en/account/recovery",
    component: () => import("/usr/src/webshop/pages/account/recovery.vue").then(m => m.default || m)
  },
  {
    name: "account-recovery___fr",
    path: "/fr/account/recovery",
    component: () => import("/usr/src/webshop/pages/account/recovery.vue").then(m => m.default || m)
  },
  {
    name: "account-recovery___cs",
    path: "/cs/account/recovery",
    component: () => import("/usr/src/webshop/pages/account/recovery.vue").then(m => m.default || m)
  },
  {
    name: "account-recovery___it",
    path: "/it/account/recovery",
    component: () => import("/usr/src/webshop/pages/account/recovery.vue").then(m => m.default || m)
  },
  {
    name: "account-recovery___pl",
    path: "/pl/account/recovery",
    component: () => import("/usr/src/webshop/pages/account/recovery.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___de",
    path: "/de/account/reset-password",
    component: () => import("/usr/src/webshop/pages/account/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___en",
    path: "/en/account/reset-password",
    component: () => import("/usr/src/webshop/pages/account/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___fr",
    path: "/fr/account/reset-password",
    component: () => import("/usr/src/webshop/pages/account/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___cs",
    path: "/cs/account/reset-password",
    component: () => import("/usr/src/webshop/pages/account/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___it",
    path: "/it/account/reset-password",
    component: () => import("/usr/src/webshop/pages/account/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___pl",
    path: "/pl/account/reset-password",
    component: () => import("/usr/src/webshop/pages/account/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "adyen-payment___de",
    path: "/de/adyen-payment",
    component: () => import("/usr/src/webshop/pages/adyen-payment.vue").then(m => m.default || m)
  },
  {
    name: "adyen-payment___en",
    path: "/en/adyen-payment",
    component: () => import("/usr/src/webshop/pages/adyen-payment.vue").then(m => m.default || m)
  },
  {
    name: "adyen-payment___fr",
    path: "/fr/adyen-payment",
    component: () => import("/usr/src/webshop/pages/adyen-payment.vue").then(m => m.default || m)
  },
  {
    name: "adyen-payment___cs",
    path: "/cs/adyen-payment",
    component: () => import("/usr/src/webshop/pages/adyen-payment.vue").then(m => m.default || m)
  },
  {
    name: "adyen-payment___it",
    path: "/it/adyen-payment",
    component: () => import("/usr/src/webshop/pages/adyen-payment.vue").then(m => m.default || m)
  },
  {
    name: "adyen-payment___pl",
    path: "/pl/adyen-payment",
    component: () => import("/usr/src/webshop/pages/adyen-payment.vue").then(m => m.default || m)
  },
  {
    name: "author-slug___de",
    path: "/de/author/:slug()",
    component: () => import("/usr/src/webshop/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "author-slug___en",
    path: "/en/author/:slug()",
    component: () => import("/usr/src/webshop/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "author-slug___fr",
    path: "/fr/author/:slug()",
    component: () => import("/usr/src/webshop/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "author-slug___cs",
    path: "/cs/author/:slug()",
    component: () => import("/usr/src/webshop/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "author-slug___it",
    path: "/it/author/:slug()",
    component: () => import("/usr/src/webshop/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "author-slug___pl",
    path: "/pl/author/:slug()",
    component: () => import("/usr/src/webshop/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "author___de",
    path: "/de/author",
    component: () => import("/usr/src/webshop/pages/author/index.vue").then(m => m.default || m)
  },
  {
    name: "author___en",
    path: "/en/author",
    component: () => import("/usr/src/webshop/pages/author/index.vue").then(m => m.default || m)
  },
  {
    name: "author___fr",
    path: "/fr/author",
    component: () => import("/usr/src/webshop/pages/author/index.vue").then(m => m.default || m)
  },
  {
    name: "author___cs",
    path: "/cs/author",
    component: () => import("/usr/src/webshop/pages/author/index.vue").then(m => m.default || m)
  },
  {
    name: "author___it",
    path: "/it/author",
    component: () => import("/usr/src/webshop/pages/author/index.vue").then(m => m.default || m)
  },
  {
    name: "author___pl",
    path: "/pl/author",
    component: () => import("/usr/src/webshop/pages/author/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___de",
    path: "/de/blog/:slug()",
    component: () => import("/usr/src/webshop/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___en",
    path: "/en/blog/:slug()",
    component: () => import("/usr/src/webshop/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___fr",
    path: "/fr/blog/:slug()",
    component: () => import("/usr/src/webshop/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___cs",
    path: "/cs/blog/:slug()",
    component: () => import("/usr/src/webshop/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___it",
    path: "/it/blog/:slug()",
    component: () => import("/usr/src/webshop/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___pl",
    path: "/pl/blog/:slug()",
    component: () => import("/usr/src/webshop/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "booklet-labels-design-details___de",
    path: "/de/booklet-labels-design-details",
    component: () => import("/usr/src/webshop/pages/booklet-labels-design-details.vue").then(m => m.default || m)
  },
  {
    name: "booklet-labels-design-details___en",
    path: "/en/booklet-labels-design-details",
    component: () => import("/usr/src/webshop/pages/booklet-labels-design-details.vue").then(m => m.default || m)
  },
  {
    name: "booklet-labels-design-details___fr",
    path: "/fr/booklet-labels-design-details",
    component: () => import("/usr/src/webshop/pages/booklet-labels-design-details.vue").then(m => m.default || m)
  },
  {
    name: "booklet-labels-design-details___cs",
    path: "/cs/booklet-labels-design-details",
    component: () => import("/usr/src/webshop/pages/booklet-labels-design-details.vue").then(m => m.default || m)
  },
  {
    name: "booklet-labels-design-details___it",
    path: "/it/booklet-labels-design-details",
    component: () => import("/usr/src/webshop/pages/booklet-labels-design-details.vue").then(m => m.default || m)
  },
  {
    name: "booklet-labels-design-details___pl",
    path: "/pl/booklet-labels-design-details",
    component: () => import("/usr/src/webshop/pages/booklet-labels-design-details.vue").then(m => m.default || m)
  },
  {
    name: "booklet-labels-form___de",
    path: "/de/booklet-labels-form",
    component: () => import("/usr/src/webshop/pages/booklet-labels-form.vue").then(m => m.default || m)
  },
  {
    name: "booklet-labels-form___en",
    path: "/en/booklet-labels-form",
    component: () => import("/usr/src/webshop/pages/booklet-labels-form.vue").then(m => m.default || m)
  },
  {
    name: "booklet-labels-form___fr",
    path: "/fr/booklet-labels-form",
    component: () => import("/usr/src/webshop/pages/booklet-labels-form.vue").then(m => m.default || m)
  },
  {
    name: "booklet-labels-form___cs",
    path: "/cs/booklet-labels-form",
    component: () => import("/usr/src/webshop/pages/booklet-labels-form.vue").then(m => m.default || m)
  },
  {
    name: "booklet-labels-form___it",
    path: "/it/booklet-labels-form",
    component: () => import("/usr/src/webshop/pages/booklet-labels-form.vue").then(m => m.default || m)
  },
  {
    name: "booklet-labels-form___pl",
    path: "/pl/booklet-labels-form",
    component: () => import("/usr/src/webshop/pages/booklet-labels-form.vue").then(m => m.default || m)
  },
  {
    name: "category-slug___de",
    path: "/de/category/:slug()",
    component: () => import("/usr/src/webshop/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "category-slug___en",
    path: "/en/category/:slug()",
    component: () => import("/usr/src/webshop/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "category-slug___fr",
    path: "/fr/category/:slug()",
    component: () => import("/usr/src/webshop/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "category-slug___cs",
    path: "/cs/category/:slug()",
    component: () => import("/usr/src/webshop/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "category-slug___it",
    path: "/it/category/:slug()",
    component: () => import("/usr/src/webshop/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "category-slug___pl",
    path: "/pl/category/:slug()",
    component: () => import("/usr/src/webshop/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "category___de",
    path: "/de/category",
    component: () => import("/usr/src/webshop/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: "category___en",
    path: "/en/category",
    component: () => import("/usr/src/webshop/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: "category___fr",
    path: "/fr/category",
    component: () => import("/usr/src/webshop/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: "category___cs",
    path: "/cs/category",
    component: () => import("/usr/src/webshop/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: "category___it",
    path: "/it/category",
    component: () => import("/usr/src/webshop/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: "category___pl",
    path: "/pl/category",
    component: () => import("/usr/src/webshop/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: "clear-cache-all___de",
    path: "/de/clear-cache-all",
    meta: clear_45cache_45all7MaRVKwKs9Meta || {},
    component: () => import("/usr/src/webshop/pages/clear-cache-all.vue").then(m => m.default || m)
  },
  {
    name: "clear-cache-all___en",
    path: "/en/clear-cache-all",
    meta: clear_45cache_45all7MaRVKwKs9Meta || {},
    component: () => import("/usr/src/webshop/pages/clear-cache-all.vue").then(m => m.default || m)
  },
  {
    name: "clear-cache-all___fr",
    path: "/fr/clear-cache-all",
    meta: clear_45cache_45all7MaRVKwKs9Meta || {},
    component: () => import("/usr/src/webshop/pages/clear-cache-all.vue").then(m => m.default || m)
  },
  {
    name: "clear-cache-all___cs",
    path: "/cs/clear-cache-all",
    meta: clear_45cache_45all7MaRVKwKs9Meta || {},
    component: () => import("/usr/src/webshop/pages/clear-cache-all.vue").then(m => m.default || m)
  },
  {
    name: "clear-cache-all___it",
    path: "/it/clear-cache-all",
    meta: clear_45cache_45all7MaRVKwKs9Meta || {},
    component: () => import("/usr/src/webshop/pages/clear-cache-all.vue").then(m => m.default || m)
  },
  {
    name: "clear-cache-all___pl",
    path: "/pl/clear-cache-all",
    meta: clear_45cache_45all7MaRVKwKs9Meta || {},
    component: () => import("/usr/src/webshop/pages/clear-cache-all.vue").then(m => m.default || m)
  },
  {
    name: "consultants___de",
    path: "/de/consultants",
    component: () => import("/usr/src/webshop/pages/consultants.vue").then(m => m.default || m)
  },
  {
    name: "consultants___en",
    path: "/en/consultants",
    component: () => import("/usr/src/webshop/pages/consultants.vue").then(m => m.default || m)
  },
  {
    name: "consultants___fr",
    path: "/fr/consultants",
    component: () => import("/usr/src/webshop/pages/consultants.vue").then(m => m.default || m)
  },
  {
    name: "consultants___cs",
    path: "/cs/consultants",
    component: () => import("/usr/src/webshop/pages/consultants.vue").then(m => m.default || m)
  },
  {
    name: "consultants___it",
    path: "/it/consultants",
    component: () => import("/usr/src/webshop/pages/consultants.vue").then(m => m.default || m)
  },
  {
    name: "consultants___pl",
    path: "/pl/consultants",
    component: () => import("/usr/src/webshop/pages/consultants.vue").then(m => m.default || m)
  },
  {
    name: "contact___de",
    path: "/de/contact",
    component: () => import("/usr/src/webshop/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/usr/src/webshop/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___fr",
    path: "/fr/contact",
    component: () => import("/usr/src/webshop/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___cs",
    path: "/cs/contact",
    component: () => import("/usr/src/webshop/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___it",
    path: "/it/contact",
    component: () => import("/usr/src/webshop/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___pl",
    path: "/pl/contact",
    component: () => import("/usr/src/webshop/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "content-slug___de",
    path: "/de/content/:slug()",
    component: () => import("/usr/src/webshop/pages/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: "content-slug___en",
    path: "/en/content/:slug()",
    component: () => import("/usr/src/webshop/pages/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: "content-slug___fr",
    path: "/fr/content/:slug()",
    component: () => import("/usr/src/webshop/pages/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: "content-slug___cs",
    path: "/cs/content/:slug()",
    component: () => import("/usr/src/webshop/pages/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: "content-slug___it",
    path: "/it/content/:slug()",
    component: () => import("/usr/src/webshop/pages/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: "content-slug___pl",
    path: "/pl/content/:slug()",
    component: () => import("/usr/src/webshop/pages/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: "custom-made-shipping-boxes-form___de",
    path: "/de/custom-made-shipping-boxes-form",
    component: () => import("/usr/src/webshop/pages/custom-made-shipping-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: "custom-made-shipping-boxes-form___en",
    path: "/en/custom-made-shipping-boxes-form",
    component: () => import("/usr/src/webshop/pages/custom-made-shipping-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: "custom-made-shipping-boxes-form___fr",
    path: "/fr/custom-made-shipping-boxes-form",
    component: () => import("/usr/src/webshop/pages/custom-made-shipping-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: "custom-made-shipping-boxes-form___cs",
    path: "/cs/custom-made-shipping-boxes-form",
    component: () => import("/usr/src/webshop/pages/custom-made-shipping-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: "custom-made-shipping-boxes-form___it",
    path: "/it/custom-made-shipping-boxes-form",
    component: () => import("/usr/src/webshop/pages/custom-made-shipping-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: "custom-made-shipping-boxes-form___pl",
    path: "/pl/custom-made-shipping-boxes-form",
    component: () => import("/usr/src/webshop/pages/custom-made-shipping-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: "data-check-slug___de",
    path: "/de/data-check/:slug()",
    component: () => import("/usr/src/webshop/pages/data-check/[slug].vue").then(m => m.default || m)
  },
  {
    name: "data-check-slug___en",
    path: "/en/data-check/:slug()",
    component: () => import("/usr/src/webshop/pages/data-check/[slug].vue").then(m => m.default || m)
  },
  {
    name: "data-check-slug___fr",
    path: "/fr/data-check/:slug()",
    component: () => import("/usr/src/webshop/pages/data-check/[slug].vue").then(m => m.default || m)
  },
  {
    name: "data-check-slug___cs",
    path: "/cs/data-check/:slug()",
    component: () => import("/usr/src/webshop/pages/data-check/[slug].vue").then(m => m.default || m)
  },
  {
    name: "data-check-slug___it",
    path: "/it/data-check/:slug()",
    component: () => import("/usr/src/webshop/pages/data-check/[slug].vue").then(m => m.default || m)
  },
  {
    name: "data-check-slug___pl",
    path: "/pl/data-check/:slug()",
    component: () => import("/usr/src/webshop/pages/data-check/[slug].vue").then(m => m.default || m)
  },
  {
    name: "data-check___de",
    path: "/de/data-check",
    component: () => import("/usr/src/webshop/pages/data-check/index.vue").then(m => m.default || m)
  },
  {
    name: "data-check___en",
    path: "/en/data-check",
    component: () => import("/usr/src/webshop/pages/data-check/index.vue").then(m => m.default || m)
  },
  {
    name: "data-check___fr",
    path: "/fr/data-check",
    component: () => import("/usr/src/webshop/pages/data-check/index.vue").then(m => m.default || m)
  },
  {
    name: "data-check___cs",
    path: "/cs/data-check",
    component: () => import("/usr/src/webshop/pages/data-check/index.vue").then(m => m.default || m)
  },
  {
    name: "data-check___it",
    path: "/it/data-check",
    component: () => import("/usr/src/webshop/pages/data-check/index.vue").then(m => m.default || m)
  },
  {
    name: "data-check___pl",
    path: "/pl/data-check",
    component: () => import("/usr/src/webshop/pages/data-check/index.vue").then(m => m.default || m)
  },
  {
    name: "delivery___de",
    path: "/de/delivery",
    component: () => import("/usr/src/webshop/pages/delivery.vue").then(m => m.default || m)
  },
  {
    name: "delivery___en",
    path: "/en/delivery",
    component: () => import("/usr/src/webshop/pages/delivery.vue").then(m => m.default || m)
  },
  {
    name: "delivery___fr",
    path: "/fr/delivery",
    component: () => import("/usr/src/webshop/pages/delivery.vue").then(m => m.default || m)
  },
  {
    name: "delivery___cs",
    path: "/cs/delivery",
    component: () => import("/usr/src/webshop/pages/delivery.vue").then(m => m.default || m)
  },
  {
    name: "delivery___it",
    path: "/it/delivery",
    component: () => import("/usr/src/webshop/pages/delivery.vue").then(m => m.default || m)
  },
  {
    name: "delivery___pl",
    path: "/pl/delivery",
    component: () => import("/usr/src/webshop/pages/delivery.vue").then(m => m.default || m)
  },
  {
    name: "dictionary___de",
    path: "/de/dictionary",
    component: () => import("/usr/src/webshop/pages/dictionary.vue").then(m => m.default || m)
  },
  {
    name: "digibox___de",
    path: "/de/digibox",
    component: () => import("/usr/src/webshop/pages/digibox.vue").then(m => m.default || m)
  },
  {
    name: "digibox___en",
    path: "/en/digibox",
    component: () => import("/usr/src/webshop/pages/digibox.vue").then(m => m.default || m)
  },
  {
    name: "digibox___fr",
    path: "/fr/digibox",
    component: () => import("/usr/src/webshop/pages/digibox.vue").then(m => m.default || m)
  },
  {
    name: "digibox___cs",
    path: "/cs/digibox",
    component: () => import("/usr/src/webshop/pages/digibox.vue").then(m => m.default || m)
  },
  {
    name: "digibox___it",
    path: "/it/digibox",
    component: () => import("/usr/src/webshop/pages/digibox.vue").then(m => m.default || m)
  },
  {
    name: "digibox___pl",
    path: "/pl/digibox",
    component: () => import("/usr/src/webshop/pages/digibox.vue").then(m => m.default || m)
  },
  {
    name: "faq-slug___de",
    path: "/de/faq/:slug()",
    component: () => import("/usr/src/webshop/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: "faq-slug___en",
    path: "/en/faq/:slug()",
    component: () => import("/usr/src/webshop/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: "faq-slug___fr",
    path: "/fr/faq/:slug()",
    component: () => import("/usr/src/webshop/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: "faq-slug___cs",
    path: "/cs/faq/:slug()",
    component: () => import("/usr/src/webshop/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: "faq-slug___it",
    path: "/it/faq/:slug()",
    component: () => import("/usr/src/webshop/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: "faq-slug___pl",
    path: "/pl/faq/:slug()",
    component: () => import("/usr/src/webshop/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: "faq___de",
    path: "/de/faq",
    component: () => import("/usr/src/webshop/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "faq___en",
    path: "/en/faq",
    component: () => import("/usr/src/webshop/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "faq___fr",
    path: "/fr/faq",
    component: () => import("/usr/src/webshop/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "faq___cs",
    path: "/cs/faq",
    component: () => import("/usr/src/webshop/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "faq___it",
    path: "/it/faq",
    component: () => import("/usr/src/webshop/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "faq___pl",
    path: "/pl/faq",
    component: () => import("/usr/src/webshop/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "folding-boxes-form___de",
    path: "/de/folding-boxes-form",
    component: () => import("/usr/src/webshop/pages/folding-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: "folding-boxes-form___en",
    path: "/en/folding-boxes-form",
    component: () => import("/usr/src/webshop/pages/folding-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: "folding-boxes-form___fr",
    path: "/fr/folding-boxes-form",
    component: () => import("/usr/src/webshop/pages/folding-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: "folding-boxes-form___cs",
    path: "/cs/folding-boxes-form",
    component: () => import("/usr/src/webshop/pages/folding-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: "folding-boxes-form___it",
    path: "/it/folding-boxes-form",
    component: () => import("/usr/src/webshop/pages/folding-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: "folding-boxes-form___pl",
    path: "/pl/folding-boxes-form",
    component: () => import("/usr/src/webshop/pages/folding-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: "food-labels-informations___de",
    path: "/de/food-labels-informations",
    component: () => import("/usr/src/webshop/pages/food-labels-informations.vue").then(m => m.default || m)
  },
  {
    name: "healthz___de",
    path: "/de/healthz",
    meta: healthzmq4s5YxpPOMeta || {},
    component: () => import("/usr/src/webshop/pages/healthz.vue").then(m => m.default || m)
  },
  {
    name: "healthz___en",
    path: "/en/healthz",
    meta: healthzmq4s5YxpPOMeta || {},
    component: () => import("/usr/src/webshop/pages/healthz.vue").then(m => m.default || m)
  },
  {
    name: "healthz___fr",
    path: "/fr/healthz",
    meta: healthzmq4s5YxpPOMeta || {},
    component: () => import("/usr/src/webshop/pages/healthz.vue").then(m => m.default || m)
  },
  {
    name: "healthz___cs",
    path: "/cs/healthz",
    meta: healthzmq4s5YxpPOMeta || {},
    component: () => import("/usr/src/webshop/pages/healthz.vue").then(m => m.default || m)
  },
  {
    name: "healthz___it",
    path: "/it/healthz",
    meta: healthzmq4s5YxpPOMeta || {},
    component: () => import("/usr/src/webshop/pages/healthz.vue").then(m => m.default || m)
  },
  {
    name: "healthz___pl",
    path: "/pl/healthz",
    meta: healthzmq4s5YxpPOMeta || {},
    component: () => import("/usr/src/webshop/pages/healthz.vue").then(m => m.default || m)
  },
  {
    name: "impressum___de",
    path: "/de/impressum",
    component: () => import("/usr/src/webshop/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: "impressum___en",
    path: "/en/impressum",
    component: () => import("/usr/src/webshop/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: "impressum___fr",
    path: "/fr/impressum",
    component: () => import("/usr/src/webshop/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: "impressum___cs",
    path: "/cs/impressum",
    component: () => import("/usr/src/webshop/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: "impressum___it",
    path: "/it/impressum",
    component: () => import("/usr/src/webshop/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: "impressum___pl",
    path: "/pl/impressum",
    component: () => import("/usr/src/webshop/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/usr/src/webshop/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/usr/src/webshop/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/usr/src/webshop/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___cs",
    path: "/cs",
    component: () => import("/usr/src/webshop/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/usr/src/webshop/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___pl",
    path: "/pl",
    component: () => import("/usr/src/webshop/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "jobs-slug___de",
    path: "/de/jobs/:slug()",
    component: () => import("/usr/src/webshop/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "jobs-slug___en",
    path: "/en/jobs/:slug()",
    component: () => import("/usr/src/webshop/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "jobs-slug___fr",
    path: "/fr/jobs/:slug()",
    component: () => import("/usr/src/webshop/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "jobs-slug___cs",
    path: "/cs/jobs/:slug()",
    component: () => import("/usr/src/webshop/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "jobs-slug___it",
    path: "/it/jobs/:slug()",
    component: () => import("/usr/src/webshop/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "jobs-slug___pl",
    path: "/pl/jobs/:slug()",
    component: () => import("/usr/src/webshop/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "jobs___de",
    path: "/de/jobs",
    component: () => import("/usr/src/webshop/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "jobs___en",
    path: "/en/jobs",
    component: () => import("/usr/src/webshop/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "jobs___fr",
    path: "/fr/jobs",
    component: () => import("/usr/src/webshop/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "jobs___cs",
    path: "/cs/jobs",
    component: () => import("/usr/src/webshop/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "jobs___it",
    path: "/it/jobs",
    component: () => import("/usr/src/webshop/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "jobs___pl",
    path: "/pl/jobs",
    component: () => import("/usr/src/webshop/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "laminate-tubes-form___de",
    path: "/de/laminate-tubes-form",
    component: () => import("/usr/src/webshop/pages/laminate-tubes-form.vue").then(m => m.default || m)
  },
  {
    name: "laminate-tubes-form___en",
    path: "/en/laminate-tubes-form",
    component: () => import("/usr/src/webshop/pages/laminate-tubes-form.vue").then(m => m.default || m)
  },
  {
    name: "laminate-tubes-form___fr",
    path: "/fr/laminate-tubes-form",
    component: () => import("/usr/src/webshop/pages/laminate-tubes-form.vue").then(m => m.default || m)
  },
  {
    name: "laminate-tubes-form___cs",
    path: "/cs/laminate-tubes-form",
    component: () => import("/usr/src/webshop/pages/laminate-tubes-form.vue").then(m => m.default || m)
  },
  {
    name: "laminate-tubes-form___it",
    path: "/it/laminate-tubes-form",
    component: () => import("/usr/src/webshop/pages/laminate-tubes-form.vue").then(m => m.default || m)
  },
  {
    name: "laminate-tubes-form___pl",
    path: "/pl/laminate-tubes-form",
    component: () => import("/usr/src/webshop/pages/laminate-tubes-form.vue").then(m => m.default || m)
  },
  {
    name: "machines___de",
    path: "/de/machines",
    component: () => import("/usr/src/webshop/pages/machines.vue").then(m => m.default || m)
  },
  {
    name: "machines___en",
    path: "/en/machines",
    component: () => import("/usr/src/webshop/pages/machines.vue").then(m => m.default || m)
  },
  {
    name: "machines___fr",
    path: "/fr/machines",
    component: () => import("/usr/src/webshop/pages/machines.vue").then(m => m.default || m)
  },
  {
    name: "machines___cs",
    path: "/cs/machines",
    component: () => import("/usr/src/webshop/pages/machines.vue").then(m => m.default || m)
  },
  {
    name: "machines___it",
    path: "/it/machines",
    component: () => import("/usr/src/webshop/pages/machines.vue").then(m => m.default || m)
  },
  {
    name: "machines___pl",
    path: "/pl/machines",
    component: () => import("/usr/src/webshop/pages/machines.vue").then(m => m.default || m)
  },
  {
    name: "magazine-slug___de",
    path: "/de/magazine/:slug()",
    component: () => import("/usr/src/webshop/pages/magazine/[slug].vue").then(m => m.default || m)
  },
  {
    name: "magazine-slug___en",
    path: "/en/magazine/:slug()",
    component: () => import("/usr/src/webshop/pages/magazine/[slug].vue").then(m => m.default || m)
  },
  {
    name: "magazine-slug___fr",
    path: "/fr/magazine/:slug()",
    component: () => import("/usr/src/webshop/pages/magazine/[slug].vue").then(m => m.default || m)
  },
  {
    name: "magazine-slug___cs",
    path: "/cs/magazine/:slug()",
    component: () => import("/usr/src/webshop/pages/magazine/[slug].vue").then(m => m.default || m)
  },
  {
    name: "magazine-slug___it",
    path: "/it/magazine/:slug()",
    component: () => import("/usr/src/webshop/pages/magazine/[slug].vue").then(m => m.default || m)
  },
  {
    name: "magazine-slug___pl",
    path: "/pl/magazine/:slug()",
    component: () => import("/usr/src/webshop/pages/magazine/[slug].vue").then(m => m.default || m)
  },
  {
    name: "magazine___de",
    path: "/de/magazine",
    component: () => import("/usr/src/webshop/pages/magazine/index.vue").then(m => m.default || m)
  },
  {
    name: "magazine___en",
    path: "/en/magazine",
    component: () => import("/usr/src/webshop/pages/magazine/index.vue").then(m => m.default || m)
  },
  {
    name: "magazine___fr",
    path: "/fr/magazine",
    component: () => import("/usr/src/webshop/pages/magazine/index.vue").then(m => m.default || m)
  },
  {
    name: "magazine___cs",
    path: "/cs/magazine",
    component: () => import("/usr/src/webshop/pages/magazine/index.vue").then(m => m.default || m)
  },
  {
    name: "magazine___it",
    path: "/it/magazine",
    component: () => import("/usr/src/webshop/pages/magazine/index.vue").then(m => m.default || m)
  },
  {
    name: "magazine___pl",
    path: "/pl/magazine",
    component: () => import("/usr/src/webshop/pages/magazine/index.vue").then(m => m.default || m)
  },
  {
    name: "major-customers-contact___de",
    path: "/de/major-customers-contact",
    component: () => import("/usr/src/webshop/pages/major-customers-contact.vue").then(m => m.default || m)
  },
  {
    name: "major-customers-contact___en",
    path: "/en/major-customers-contact",
    component: () => import("/usr/src/webshop/pages/major-customers-contact.vue").then(m => m.default || m)
  },
  {
    name: "major-customers-contact___fr",
    path: "/fr/major-customers-contact",
    component: () => import("/usr/src/webshop/pages/major-customers-contact.vue").then(m => m.default || m)
  },
  {
    name: "major-customers-contact___cs",
    path: "/cs/major-customers-contact",
    component: () => import("/usr/src/webshop/pages/major-customers-contact.vue").then(m => m.default || m)
  },
  {
    name: "major-customers-contact___it",
    path: "/it/major-customers-contact",
    component: () => import("/usr/src/webshop/pages/major-customers-contact.vue").then(m => m.default || m)
  },
  {
    name: "major-customers-contact___pl",
    path: "/pl/major-customers-contact",
    component: () => import("/usr/src/webshop/pages/major-customers-contact.vue").then(m => m.default || m)
  },
  {
    name: "major-customers___de",
    path: "/de/major-customers",
    component: () => import("/usr/src/webshop/pages/major-customers.vue").then(m => m.default || m)
  },
  {
    name: "major-customers___en",
    path: "/en/major-customers",
    component: () => import("/usr/src/webshop/pages/major-customers.vue").then(m => m.default || m)
  },
  {
    name: "major-customers___fr",
    path: "/fr/major-customers",
    component: () => import("/usr/src/webshop/pages/major-customers.vue").then(m => m.default || m)
  },
  {
    name: "major-customers___cs",
    path: "/cs/major-customers",
    component: () => import("/usr/src/webshop/pages/major-customers.vue").then(m => m.default || m)
  },
  {
    name: "major-customers___it",
    path: "/it/major-customers",
    component: () => import("/usr/src/webshop/pages/major-customers.vue").then(m => m.default || m)
  },
  {
    name: "major-customers___pl",
    path: "/pl/major-customers",
    component: () => import("/usr/src/webshop/pages/major-customers.vue").then(m => m.default || m)
  },
  {
    name: "materials-slug___de",
    path: "/de/materials/:slug()",
    component: () => import("/usr/src/webshop/pages/materials/[slug].vue").then(m => m.default || m)
  },
  {
    name: "materials-slug___en",
    path: "/en/materials/:slug()",
    component: () => import("/usr/src/webshop/pages/materials/[slug].vue").then(m => m.default || m)
  },
  {
    name: "materials-slug___fr",
    path: "/fr/materials/:slug()",
    component: () => import("/usr/src/webshop/pages/materials/[slug].vue").then(m => m.default || m)
  },
  {
    name: "materials-slug___cs",
    path: "/cs/materials/:slug()",
    component: () => import("/usr/src/webshop/pages/materials/[slug].vue").then(m => m.default || m)
  },
  {
    name: "materials-slug___it",
    path: "/it/materials/:slug()",
    component: () => import("/usr/src/webshop/pages/materials/[slug].vue").then(m => m.default || m)
  },
  {
    name: "materials-slug___pl",
    path: "/pl/materials/:slug()",
    component: () => import("/usr/src/webshop/pages/materials/[slug].vue").then(m => m.default || m)
  },
  {
    name: "materials___de",
    path: "/de/materials",
    component: () => import("/usr/src/webshop/pages/materials/index.vue").then(m => m.default || m)
  },
  {
    name: "materials___en",
    path: "/en/materials",
    component: () => import("/usr/src/webshop/pages/materials/index.vue").then(m => m.default || m)
  },
  {
    name: "materials___fr",
    path: "/fr/materials",
    component: () => import("/usr/src/webshop/pages/materials/index.vue").then(m => m.default || m)
  },
  {
    name: "materials___cs",
    path: "/cs/materials",
    component: () => import("/usr/src/webshop/pages/materials/index.vue").then(m => m.default || m)
  },
  {
    name: "materials___it",
    path: "/it/materials",
    component: () => import("/usr/src/webshop/pages/materials/index.vue").then(m => m.default || m)
  },
  {
    name: "materials___pl",
    path: "/pl/materials",
    component: () => import("/usr/src/webshop/pages/materials/index.vue").then(m => m.default || m)
  },
  {
    name: "mellerud___de",
    path: "/de/mellerud",
    component: () => import("/usr/src/webshop/pages/mellerud.vue").then(m => m.default || m)
  },
  {
    name: "mellerud___en",
    path: "/en/mellerud",
    component: () => import("/usr/src/webshop/pages/mellerud.vue").then(m => m.default || m)
  },
  {
    name: "mellerud___fr",
    path: "/fr/mellerud",
    component: () => import("/usr/src/webshop/pages/mellerud.vue").then(m => m.default || m)
  },
  {
    name: "mellerud___cs",
    path: "/cs/mellerud",
    component: () => import("/usr/src/webshop/pages/mellerud.vue").then(m => m.default || m)
  },
  {
    name: "mellerud___it",
    path: "/it/mellerud",
    component: () => import("/usr/src/webshop/pages/mellerud.vue").then(m => m.default || m)
  },
  {
    name: "mellerud___pl",
    path: "/pl/mellerud",
    component: () => import("/usr/src/webshop/pages/mellerud.vue").then(m => m.default || m)
  },
  {
    name: "mymuesli___de",
    path: "/de/mymuesli",
    component: () => import("/usr/src/webshop/pages/mymuesli.vue").then(m => m.default || m)
  },
  {
    name: "mymuesli___en",
    path: "/en/mymuesli",
    component: () => import("/usr/src/webshop/pages/mymuesli.vue").then(m => m.default || m)
  },
  {
    name: "mymuesli___fr",
    path: "/fr/mymuesli",
    component: () => import("/usr/src/webshop/pages/mymuesli.vue").then(m => m.default || m)
  },
  {
    name: "mymuesli___cs",
    path: "/cs/mymuesli",
    component: () => import("/usr/src/webshop/pages/mymuesli.vue").then(m => m.default || m)
  },
  {
    name: "mymuesli___it",
    path: "/it/mymuesli",
    component: () => import("/usr/src/webshop/pages/mymuesli.vue").then(m => m.default || m)
  },
  {
    name: "mymuesli___pl",
    path: "/pl/mymuesli",
    component: () => import("/usr/src/webshop/pages/mymuesli.vue").then(m => m.default || m)
  },
  {
    name: "mymusli___de",
    path: "/de/mymusli",
    component: () => import("/usr/src/webshop/pages/mymusli.vue").then(m => m.default || m)
  },
  {
    name: "mymusli___en",
    path: "/en/mymusli",
    component: () => import("/usr/src/webshop/pages/mymusli.vue").then(m => m.default || m)
  },
  {
    name: "mymusli___fr",
    path: "/fr/mymusli",
    component: () => import("/usr/src/webshop/pages/mymusli.vue").then(m => m.default || m)
  },
  {
    name: "mymusli___cs",
    path: "/cs/mymusli",
    component: () => import("/usr/src/webshop/pages/mymusli.vue").then(m => m.default || m)
  },
  {
    name: "mymusli___it",
    path: "/it/mymusli",
    component: () => import("/usr/src/webshop/pages/mymusli.vue").then(m => m.default || m)
  },
  {
    name: "mymusli___pl",
    path: "/pl/mymusli",
    component: () => import("/usr/src/webshop/pages/mymusli.vue").then(m => m.default || m)
  },
  {
    name: "newsletter-confirmation___de",
    path: "/de/newsletter-confirmation",
    component: () => import("/usr/src/webshop/pages/newsletter-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "newsletter-confirmation___en",
    path: "/en/newsletter-confirmation",
    component: () => import("/usr/src/webshop/pages/newsletter-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "newsletter-confirmation___fr",
    path: "/fr/newsletter-confirmation",
    component: () => import("/usr/src/webshop/pages/newsletter-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "newsletter-confirmation___cs",
    path: "/cs/newsletter-confirmation",
    component: () => import("/usr/src/webshop/pages/newsletter-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "newsletter-confirmation___it",
    path: "/it/newsletter-confirmation",
    component: () => import("/usr/src/webshop/pages/newsletter-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "newsletter-confirmation___pl",
    path: "/pl/newsletter-confirmation",
    component: () => import("/usr/src/webshop/pages/newsletter-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "order-address___de",
    path: "/de/order/address",
    component: () => import("/usr/src/webshop/pages/order/address.vue").then(m => m.default || m)
  },
  {
    name: "order-address___en",
    path: "/en/order/address",
    component: () => import("/usr/src/webshop/pages/order/address.vue").then(m => m.default || m)
  },
  {
    name: "order-address___fr",
    path: "/fr/order/address",
    component: () => import("/usr/src/webshop/pages/order/address.vue").then(m => m.default || m)
  },
  {
    name: "order-address___cs",
    path: "/cs/order/address",
    component: () => import("/usr/src/webshop/pages/order/address.vue").then(m => m.default || m)
  },
  {
    name: "order-address___it",
    path: "/it/order/address",
    component: () => import("/usr/src/webshop/pages/order/address.vue").then(m => m.default || m)
  },
  {
    name: "order-address___pl",
    path: "/pl/order/address",
    component: () => import("/usr/src/webshop/pages/order/address.vue").then(m => m.default || m)
  },
  {
    name: "order-card___de",
    path: "/de/order/card",
    component: () => import("/usr/src/webshop/pages/order/card.vue").then(m => m.default || m)
  },
  {
    name: "order-card___en",
    path: "/en/order/card",
    component: () => import("/usr/src/webshop/pages/order/card.vue").then(m => m.default || m)
  },
  {
    name: "order-card___fr",
    path: "/fr/order/card",
    component: () => import("/usr/src/webshop/pages/order/card.vue").then(m => m.default || m)
  },
  {
    name: "order-card___cs",
    path: "/cs/order/card",
    component: () => import("/usr/src/webshop/pages/order/card.vue").then(m => m.default || m)
  },
  {
    name: "order-card___it",
    path: "/it/order/card",
    component: () => import("/usr/src/webshop/pages/order/card.vue").then(m => m.default || m)
  },
  {
    name: "order-card___pl",
    path: "/pl/order/card",
    component: () => import("/usr/src/webshop/pages/order/card.vue").then(m => m.default || m)
  },
  {
    name: "order-login___de",
    path: "/de/order/login",
    component: () => import("/usr/src/webshop/pages/order/login.vue").then(m => m.default || m)
  },
  {
    name: "order-login___en",
    path: "/en/order/login",
    component: () => import("/usr/src/webshop/pages/order/login.vue").then(m => m.default || m)
  },
  {
    name: "order-login___fr",
    path: "/fr/order/login",
    component: () => import("/usr/src/webshop/pages/order/login.vue").then(m => m.default || m)
  },
  {
    name: "order-login___cs",
    path: "/cs/order/login",
    component: () => import("/usr/src/webshop/pages/order/login.vue").then(m => m.default || m)
  },
  {
    name: "order-login___it",
    path: "/it/order/login",
    component: () => import("/usr/src/webshop/pages/order/login.vue").then(m => m.default || m)
  },
  {
    name: "order-login___pl",
    path: "/pl/order/login",
    component: () => import("/usr/src/webshop/pages/order/login.vue").then(m => m.default || m)
  },
  {
    name: "order-overview___de",
    path: "/de/order/overview",
    meta: overviewDpi4KRAgfiMeta || {},
    component: () => import("/usr/src/webshop/pages/order/overview.vue").then(m => m.default || m)
  },
  {
    name: "order-overview___en",
    path: "/en/order/overview",
    meta: overviewDpi4KRAgfiMeta || {},
    component: () => import("/usr/src/webshop/pages/order/overview.vue").then(m => m.default || m)
  },
  {
    name: "order-overview___fr",
    path: "/fr/order/overview",
    meta: overviewDpi4KRAgfiMeta || {},
    component: () => import("/usr/src/webshop/pages/order/overview.vue").then(m => m.default || m)
  },
  {
    name: "order-overview___cs",
    path: "/cs/order/overview",
    meta: overviewDpi4KRAgfiMeta || {},
    component: () => import("/usr/src/webshop/pages/order/overview.vue").then(m => m.default || m)
  },
  {
    name: "order-overview___it",
    path: "/it/order/overview",
    meta: overviewDpi4KRAgfiMeta || {},
    component: () => import("/usr/src/webshop/pages/order/overview.vue").then(m => m.default || m)
  },
  {
    name: "order-overview___pl",
    path: "/pl/order/overview",
    meta: overviewDpi4KRAgfiMeta || {},
    component: () => import("/usr/src/webshop/pages/order/overview.vue").then(m => m.default || m)
  },
  {
    name: "order-printdata___de",
    path: "/de/order/printdata",
    component: () => import("/usr/src/webshop/pages/order/printdata.vue").then(m => m.default || m)
  },
  {
    name: "order-printdata___en",
    path: "/en/order/printdata",
    component: () => import("/usr/src/webshop/pages/order/printdata.vue").then(m => m.default || m)
  },
  {
    name: "order-printdata___fr",
    path: "/fr/order/printdata",
    component: () => import("/usr/src/webshop/pages/order/printdata.vue").then(m => m.default || m)
  },
  {
    name: "order-printdata___cs",
    path: "/cs/order/printdata",
    component: () => import("/usr/src/webshop/pages/order/printdata.vue").then(m => m.default || m)
  },
  {
    name: "order-printdata___it",
    path: "/it/order/printdata",
    component: () => import("/usr/src/webshop/pages/order/printdata.vue").then(m => m.default || m)
  },
  {
    name: "order-printdata___pl",
    path: "/pl/order/printdata",
    component: () => import("/usr/src/webshop/pages/order/printdata.vue").then(m => m.default || m)
  },
  {
    name: "order-shipping___de",
    path: "/de/order/shipping",
    meta: shipping8QRsAaIan2Meta || {},
    component: () => import("/usr/src/webshop/pages/order/shipping.vue").then(m => m.default || m)
  },
  {
    name: "order-shipping___en",
    path: "/en/order/shipping",
    meta: shipping8QRsAaIan2Meta || {},
    component: () => import("/usr/src/webshop/pages/order/shipping.vue").then(m => m.default || m)
  },
  {
    name: "order-shipping___fr",
    path: "/fr/order/shipping",
    meta: shipping8QRsAaIan2Meta || {},
    component: () => import("/usr/src/webshop/pages/order/shipping.vue").then(m => m.default || m)
  },
  {
    name: "order-shipping___cs",
    path: "/cs/order/shipping",
    meta: shipping8QRsAaIan2Meta || {},
    component: () => import("/usr/src/webshop/pages/order/shipping.vue").then(m => m.default || m)
  },
  {
    name: "order-shipping___it",
    path: "/it/order/shipping",
    meta: shipping8QRsAaIan2Meta || {},
    component: () => import("/usr/src/webshop/pages/order/shipping.vue").then(m => m.default || m)
  },
  {
    name: "order-shipping___pl",
    path: "/pl/order/shipping",
    meta: shipping8QRsAaIan2Meta || {},
    component: () => import("/usr/src/webshop/pages/order/shipping.vue").then(m => m.default || m)
  },
  {
    name: "order-thank-you___de",
    path: "/de/order/thank-you",
    meta: thank_45youw64uWVYufPMeta || {},
    component: () => import("/usr/src/webshop/pages/order/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "order-thank-you___en",
    path: "/en/order/thank-you",
    meta: thank_45youw64uWVYufPMeta || {},
    component: () => import("/usr/src/webshop/pages/order/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "order-thank-you___fr",
    path: "/fr/order/thank-you",
    meta: thank_45youw64uWVYufPMeta || {},
    component: () => import("/usr/src/webshop/pages/order/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "order-thank-you___cs",
    path: "/cs/order/thank-you",
    meta: thank_45youw64uWVYufPMeta || {},
    component: () => import("/usr/src/webshop/pages/order/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "order-thank-you___it",
    path: "/it/order/thank-you",
    meta: thank_45youw64uWVYufPMeta || {},
    component: () => import("/usr/src/webshop/pages/order/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "order-thank-you___pl",
    path: "/pl/order/thank-you",
    meta: thank_45youw64uWVYufPMeta || {},
    component: () => import("/usr/src/webshop/pages/order/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "our-promise___de",
    path: "/de/our-promise",
    component: () => import("/usr/src/webshop/pages/our-promise.vue").then(m => m.default || m)
  },
  {
    name: "our-promise___en",
    path: "/en/our-promise",
    component: () => import("/usr/src/webshop/pages/our-promise.vue").then(m => m.default || m)
  },
  {
    name: "our-promise___fr",
    path: "/fr/our-promise",
    component: () => import("/usr/src/webshop/pages/our-promise.vue").then(m => m.default || m)
  },
  {
    name: "our-promise___cs",
    path: "/cs/our-promise",
    component: () => import("/usr/src/webshop/pages/our-promise.vue").then(m => m.default || m)
  },
  {
    name: "our-promise___it",
    path: "/it/our-promise",
    component: () => import("/usr/src/webshop/pages/our-promise.vue").then(m => m.default || m)
  },
  {
    name: "our-promise___pl",
    path: "/pl/our-promise",
    component: () => import("/usr/src/webshop/pages/our-promise.vue").then(m => m.default || m)
  },
  {
    name: "package-leaflets-package-inserts-form___de",
    path: "/de/package-leaflets-package-inserts-form",
    component: () => import("/usr/src/webshop/pages/package-leaflets-package-inserts-form.vue").then(m => m.default || m)
  },
  {
    name: "package-leaflets-package-inserts-form___en",
    path: "/en/package-leaflets-package-inserts-form",
    component: () => import("/usr/src/webshop/pages/package-leaflets-package-inserts-form.vue").then(m => m.default || m)
  },
  {
    name: "package-leaflets-package-inserts-form___fr",
    path: "/fr/package-leaflets-package-inserts-form",
    component: () => import("/usr/src/webshop/pages/package-leaflets-package-inserts-form.vue").then(m => m.default || m)
  },
  {
    name: "package-leaflets-package-inserts-form___cs",
    path: "/cs/package-leaflets-package-inserts-form",
    component: () => import("/usr/src/webshop/pages/package-leaflets-package-inserts-form.vue").then(m => m.default || m)
  },
  {
    name: "package-leaflets-package-inserts-form___it",
    path: "/it/package-leaflets-package-inserts-form",
    component: () => import("/usr/src/webshop/pages/package-leaflets-package-inserts-form.vue").then(m => m.default || m)
  },
  {
    name: "package-leaflets-package-inserts-form___pl",
    path: "/pl/package-leaflets-package-inserts-form",
    component: () => import("/usr/src/webshop/pages/package-leaflets-package-inserts-form.vue").then(m => m.default || m)
  },
  {
    name: "paypal-paypal_listener___de",
    path: "/de/paypal/paypal_listener",
    component: () => import("/usr/src/webshop/pages/paypal/paypal_listener.vue").then(m => m.default || m)
  },
  {
    name: "paypal-paypal_listener___en",
    path: "/en/paypal/paypal_listener",
    component: () => import("/usr/src/webshop/pages/paypal/paypal_listener.vue").then(m => m.default || m)
  },
  {
    name: "paypal-paypal_listener___fr",
    path: "/fr/paypal/paypal_listener",
    component: () => import("/usr/src/webshop/pages/paypal/paypal_listener.vue").then(m => m.default || m)
  },
  {
    name: "paypal-paypal_listener___cs",
    path: "/cs/paypal/paypal_listener",
    component: () => import("/usr/src/webshop/pages/paypal/paypal_listener.vue").then(m => m.default || m)
  },
  {
    name: "paypal-paypal_listener___it",
    path: "/it/paypal/paypal_listener",
    component: () => import("/usr/src/webshop/pages/paypal/paypal_listener.vue").then(m => m.default || m)
  },
  {
    name: "paypal-paypal_listener___pl",
    path: "/pl/paypal/paypal_listener",
    component: () => import("/usr/src/webshop/pages/paypal/paypal_listener.vue").then(m => m.default || m)
  },
  {
    name: "print-data___de",
    path: "/de/print-data",
    component: () => import("/usr/src/webshop/pages/print-data/index.vue").then(m => m.default || m)
  },
  {
    name: "print-data___en",
    path: "/en/print-data",
    component: () => import("/usr/src/webshop/pages/print-data/index.vue").then(m => m.default || m)
  },
  {
    name: "print-data___fr",
    path: "/fr/print-data",
    component: () => import("/usr/src/webshop/pages/print-data/index.vue").then(m => m.default || m)
  },
  {
    name: "print-data___cs",
    path: "/cs/print-data",
    component: () => import("/usr/src/webshop/pages/print-data/index.vue").then(m => m.default || m)
  },
  {
    name: "print-data___it",
    path: "/it/print-data",
    component: () => import("/usr/src/webshop/pages/print-data/index.vue").then(m => m.default || m)
  },
  {
    name: "print-data___pl",
    path: "/pl/print-data",
    component: () => import("/usr/src/webshop/pages/print-data/index.vue").then(m => m.default || m)
  },
  {
    name: "print-data-repair___de",
    path: "/de/print-data/repair",
    component: () => import("/usr/src/webshop/pages/print-data/repair.vue").then(m => m.default || m)
  },
  {
    name: "print-data-repair___en",
    path: "/en/print-data/repair",
    component: () => import("/usr/src/webshop/pages/print-data/repair.vue").then(m => m.default || m)
  },
  {
    name: "print-data-repair___fr",
    path: "/fr/print-data/repair",
    component: () => import("/usr/src/webshop/pages/print-data/repair.vue").then(m => m.default || m)
  },
  {
    name: "print-data-repair___cs",
    path: "/cs/print-data/repair",
    component: () => import("/usr/src/webshop/pages/print-data/repair.vue").then(m => m.default || m)
  },
  {
    name: "print-data-repair___it",
    path: "/it/print-data/repair",
    component: () => import("/usr/src/webshop/pages/print-data/repair.vue").then(m => m.default || m)
  },
  {
    name: "print-data-repair___pl",
    path: "/pl/print-data/repair",
    component: () => import("/usr/src/webshop/pages/print-data/repair.vue").then(m => m.default || m)
  },
  {
    name: "printed-labels-form___de",
    path: "/de/printed-labels-form",
    component: () => import("/usr/src/webshop/pages/printed-labels-form.vue").then(m => m.default || m)
  },
  {
    name: "printed-labels-form___en",
    path: "/en/printed-labels-form",
    component: () => import("/usr/src/webshop/pages/printed-labels-form.vue").then(m => m.default || m)
  },
  {
    name: "printed-labels-form___fr",
    path: "/fr/printed-labels-form",
    component: () => import("/usr/src/webshop/pages/printed-labels-form.vue").then(m => m.default || m)
  },
  {
    name: "printed-labels-form___cs",
    path: "/cs/printed-labels-form",
    component: () => import("/usr/src/webshop/pages/printed-labels-form.vue").then(m => m.default || m)
  },
  {
    name: "printed-labels-form___it",
    path: "/it/printed-labels-form",
    component: () => import("/usr/src/webshop/pages/printed-labels-form.vue").then(m => m.default || m)
  },
  {
    name: "printed-labels-form___pl",
    path: "/pl/printed-labels-form",
    component: () => import("/usr/src/webshop/pages/printed-labels-form.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___de",
    path: "/de/privacy-policy",
    component: () => import("/usr/src/webshop/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    component: () => import("/usr/src/webshop/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___fr",
    path: "/fr/privacy-policy",
    component: () => import("/usr/src/webshop/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___cs",
    path: "/cs/privacy-policy",
    component: () => import("/usr/src/webshop/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___it",
    path: "/it/privacy-policy",
    component: () => import("/usr/src/webshop/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___pl",
    path: "/pl/privacy-policy",
    component: () => import("/usr/src/webshop/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "products-slug___de",
    path: "/de/products/:slug()",
    component: () => import("/usr/src/webshop/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: "products-slug___en",
    path: "/en/products/:slug()",
    component: () => import("/usr/src/webshop/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: "products-slug___fr",
    path: "/fr/products/:slug()",
    component: () => import("/usr/src/webshop/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: "products-slug___cs",
    path: "/cs/products/:slug()",
    component: () => import("/usr/src/webshop/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: "products-slug___it",
    path: "/it/products/:slug()",
    component: () => import("/usr/src/webshop/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: "products-slug___pl",
    path: "/pl/products/:slug()",
    component: () => import("/usr/src/webshop/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: "products___de",
    path: "/de/products",
    component: () => import("/usr/src/webshop/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "products___en",
    path: "/en/products",
    component: () => import("/usr/src/webshop/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "products___fr",
    path: "/fr/products",
    component: () => import("/usr/src/webshop/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "products___cs",
    path: "/cs/products",
    component: () => import("/usr/src/webshop/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "products___it",
    path: "/it/products",
    component: () => import("/usr/src/webshop/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "products___pl",
    path: "/pl/products",
    component: () => import("/usr/src/webshop/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "redirect-to-paypal___de",
    path: "/de/redirect-to-paypal",
    component: () => import("/usr/src/webshop/pages/redirect-to-paypal.vue").then(m => m.default || m)
  },
  {
    name: "redirect-to-paypal___en",
    path: "/en/redirect-to-paypal",
    component: () => import("/usr/src/webshop/pages/redirect-to-paypal.vue").then(m => m.default || m)
  },
  {
    name: "redirect-to-paypal___fr",
    path: "/fr/redirect-to-paypal",
    component: () => import("/usr/src/webshop/pages/redirect-to-paypal.vue").then(m => m.default || m)
  },
  {
    name: "redirect-to-paypal___cs",
    path: "/cs/redirect-to-paypal",
    component: () => import("/usr/src/webshop/pages/redirect-to-paypal.vue").then(m => m.default || m)
  },
  {
    name: "redirect-to-paypal___it",
    path: "/it/redirect-to-paypal",
    component: () => import("/usr/src/webshop/pages/redirect-to-paypal.vue").then(m => m.default || m)
  },
  {
    name: "redirect-to-paypal___pl",
    path: "/pl/redirect-to-paypal",
    component: () => import("/usr/src/webshop/pages/redirect-to-paypal.vue").then(m => m.default || m)
  },
  {
    name: "sample-corrugated-cardboard___de",
    path: "/de/sample-corrugated-cardboard",
    component: () => import("/usr/src/webshop/pages/sample-corrugated-cardboard.vue").then(m => m.default || m)
  },
  {
    name: "sample-corrugated-cardboard___en",
    path: "/en/sample-corrugated-cardboard",
    component: () => import("/usr/src/webshop/pages/sample-corrugated-cardboard.vue").then(m => m.default || m)
  },
  {
    name: "sample-corrugated-cardboard___fr",
    path: "/fr/sample-corrugated-cardboard",
    component: () => import("/usr/src/webshop/pages/sample-corrugated-cardboard.vue").then(m => m.default || m)
  },
  {
    name: "sample-corrugated-cardboard___cs",
    path: "/cs/sample-corrugated-cardboard",
    component: () => import("/usr/src/webshop/pages/sample-corrugated-cardboard.vue").then(m => m.default || m)
  },
  {
    name: "sample-corrugated-cardboard___it",
    path: "/it/sample-corrugated-cardboard",
    component: () => import("/usr/src/webshop/pages/sample-corrugated-cardboard.vue").then(m => m.default || m)
  },
  {
    name: "sample-corrugated-cardboard___pl",
    path: "/pl/sample-corrugated-cardboard",
    component: () => import("/usr/src/webshop/pages/sample-corrugated-cardboard.vue").then(m => m.default || m)
  },
  {
    name: "sample___de",
    path: "/de/sample",
    component: () => import("/usr/src/webshop/pages/sample.vue").then(m => m.default || m)
  },
  {
    name: "sample___en",
    path: "/en/sample",
    component: () => import("/usr/src/webshop/pages/sample.vue").then(m => m.default || m)
  },
  {
    name: "sample___fr",
    path: "/fr/sample",
    component: () => import("/usr/src/webshop/pages/sample.vue").then(m => m.default || m)
  },
  {
    name: "sample___cs",
    path: "/cs/sample",
    component: () => import("/usr/src/webshop/pages/sample.vue").then(m => m.default || m)
  },
  {
    name: "sample___it",
    path: "/it/sample",
    component: () => import("/usr/src/webshop/pages/sample.vue").then(m => m.default || m)
  },
  {
    name: "sample___pl",
    path: "/pl/sample",
    component: () => import("/usr/src/webshop/pages/sample.vue").then(m => m.default || m)
  },
  {
    name: "sampletube___de",
    path: "/de/sampletube",
    component: () => import("/usr/src/webshop/pages/sampletube.vue").then(m => m.default || m)
  },
  {
    name: "sampletube___en",
    path: "/en/sampletube",
    component: () => import("/usr/src/webshop/pages/sampletube.vue").then(m => m.default || m)
  },
  {
    name: "sampletube___fr",
    path: "/fr/sampletube",
    component: () => import("/usr/src/webshop/pages/sampletube.vue").then(m => m.default || m)
  },
  {
    name: "sampletube___cs",
    path: "/cs/sampletube",
    component: () => import("/usr/src/webshop/pages/sampletube.vue").then(m => m.default || m)
  },
  {
    name: "sampletube___it",
    path: "/it/sampletube",
    component: () => import("/usr/src/webshop/pages/sampletube.vue").then(m => m.default || m)
  },
  {
    name: "sampletube___pl",
    path: "/pl/sampletube",
    component: () => import("/usr/src/webshop/pages/sampletube.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___de",
    path: "/de/sitemap",
    component: () => import("/usr/src/webshop/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___en",
    path: "/en/sitemap",
    component: () => import("/usr/src/webshop/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___fr",
    path: "/fr/sitemap",
    component: () => import("/usr/src/webshop/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___cs",
    path: "/cs/sitemap",
    component: () => import("/usr/src/webshop/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___it",
    path: "/it/sitemap",
    component: () => import("/usr/src/webshop/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___pl",
    path: "/pl/sitemap",
    component: () => import("/usr/src/webshop/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sustainability___de",
    path: "/de/sustainability",
    component: () => import("/usr/src/webshop/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: "sustainability___en",
    path: "/en/sustainability",
    component: () => import("/usr/src/webshop/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: "sustainability___fr",
    path: "/fr/sustainability",
    component: () => import("/usr/src/webshop/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: "sustainability___cs",
    path: "/cs/sustainability",
    component: () => import("/usr/src/webshop/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: "sustainability___it",
    path: "/it/sustainability",
    component: () => import("/usr/src/webshop/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: "sustainability___pl",
    path: "/pl/sustainability",
    component: () => import("/usr/src/webshop/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: "swiss-fair___de",
    path: "/de/swiss-fair",
    component: () => import("/usr/src/webshop/pages/swiss-fair.vue").then(m => m.default || m)
  },
  {
    name: "swiss-fair___en",
    path: "/en/swiss-fair",
    component: () => import("/usr/src/webshop/pages/swiss-fair.vue").then(m => m.default || m)
  },
  {
    name: "swiss-fair___fr",
    path: "/fr/swiss-fair",
    component: () => import("/usr/src/webshop/pages/swiss-fair.vue").then(m => m.default || m)
  },
  {
    name: "swiss-fair___cs",
    path: "/cs/swiss-fair",
    component: () => import("/usr/src/webshop/pages/swiss-fair.vue").then(m => m.default || m)
  },
  {
    name: "swiss-fair___it",
    path: "/it/swiss-fair",
    component: () => import("/usr/src/webshop/pages/swiss-fair.vue").then(m => m.default || m)
  },
  {
    name: "swiss-fair___pl",
    path: "/pl/swiss-fair",
    component: () => import("/usr/src/webshop/pages/swiss-fair.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service___de",
    path: "/de/terms-of-service",
    component: () => import("/usr/src/webshop/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service___en",
    path: "/en/terms-of-service",
    component: () => import("/usr/src/webshop/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service___fr",
    path: "/fr/terms-of-service",
    component: () => import("/usr/src/webshop/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service___cs",
    path: "/cs/terms-of-service",
    component: () => import("/usr/src/webshop/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service___it",
    path: "/it/terms-of-service",
    component: () => import("/usr/src/webshop/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service___pl",
    path: "/pl/terms-of-service",
    component: () => import("/usr/src/webshop/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "versionabout-us___de",
    path: "/de/versions/about-us",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "versionabout-us___en",
    path: "/en/versions/about-us",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "versionabout-us___fr",
    path: "/fr/versions/about-us",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "versionabout-us___cs",
    path: "/cs/versions/about-us",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "versionabout-us___it",
    path: "/it/versions/about-us",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "versionabout-us___pl",
    path: "/pl/versions/about-us",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-login-callback___de",
    path: "/de/versions/account/login/callback",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/login/callback.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-login-callback___en",
    path: "/en/versions/account/login/callback",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/login/callback.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-login-callback___fr",
    path: "/fr/versions/account/login/callback",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/login/callback.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-login-callback___cs",
    path: "/cs/versions/account/login/callback",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/login/callback.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-login-callback___it",
    path: "/it/versions/account/login/callback",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/login/callback.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-login-callback___pl",
    path: "/pl/versions/account/login/callback",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/login/callback.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-login___de",
    path: "/de/versions/account/login",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-login___en",
    path: "/en/versions/account/login",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-login___fr",
    path: "/fr/versions/account/login",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-login___cs",
    path: "/cs/versions/account/login",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-login___it",
    path: "/it/versions/account/login",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-login___pl",
    path: "/pl/versions/account/login",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-order-slug___de",
    path: "/de/versions/account/order/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/order/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-order-slug___en",
    path: "/en/versions/account/order/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/order/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-order-slug___fr",
    path: "/fr/versions/account/order/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/order/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-order-slug___cs",
    path: "/cs/versions/account/order/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/order/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-order-slug___it",
    path: "/it/versions/account/order/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/order/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-order-slug___pl",
    path: "/pl/versions/account/order/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/order/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-overview___de",
    path: "/de/versions/account/overview",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/overview.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-overview___en",
    path: "/en/versions/account/overview",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/overview.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-overview___fr",
    path: "/fr/versions/account/overview",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/overview.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-overview___cs",
    path: "/cs/versions/account/overview",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/overview.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-overview___it",
    path: "/it/versions/account/overview",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/overview.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-overview___pl",
    path: "/pl/versions/account/overview",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/overview.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-recovery___de",
    path: "/de/versions/account/recovery",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/recovery.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-recovery___en",
    path: "/en/versions/account/recovery",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/recovery.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-recovery___fr",
    path: "/fr/versions/account/recovery",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/recovery.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-recovery___cs",
    path: "/cs/versions/account/recovery",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/recovery.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-recovery___it",
    path: "/it/versions/account/recovery",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/recovery.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-recovery___pl",
    path: "/pl/versions/account/recovery",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/recovery.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-reset-password___de",
    path: "/de/versions/account/reset-password",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-reset-password___en",
    path: "/en/versions/account/reset-password",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-reset-password___fr",
    path: "/fr/versions/account/reset-password",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-reset-password___cs",
    path: "/cs/versions/account/reset-password",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-reset-password___it",
    path: "/it/versions/account/reset-password",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "versionaccount-reset-password___pl",
    path: "/pl/versions/account/reset-password",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/account/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "versionadyen-payment___de",
    path: "/de/versions/adyen-payment",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/adyen-payment.vue").then(m => m.default || m)
  },
  {
    name: "versionadyen-payment___en",
    path: "/en/versions/adyen-payment",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/adyen-payment.vue").then(m => m.default || m)
  },
  {
    name: "versionadyen-payment___fr",
    path: "/fr/versions/adyen-payment",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/adyen-payment.vue").then(m => m.default || m)
  },
  {
    name: "versionadyen-payment___cs",
    path: "/cs/versions/adyen-payment",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/adyen-payment.vue").then(m => m.default || m)
  },
  {
    name: "versionadyen-payment___it",
    path: "/it/versions/adyen-payment",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/adyen-payment.vue").then(m => m.default || m)
  },
  {
    name: "versionadyen-payment___pl",
    path: "/pl/versions/adyen-payment",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/adyen-payment.vue").then(m => m.default || m)
  },
  {
    name: "versionauthor-slug___de",
    path: "/de/versions/author/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionauthor-slug___en",
    path: "/en/versions/author/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionauthor-slug___fr",
    path: "/fr/versions/author/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionauthor-slug___cs",
    path: "/cs/versions/author/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionauthor-slug___it",
    path: "/it/versions/author/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionauthor-slug___pl",
    path: "/pl/versions/author/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionauthor___de",
    path: "/de/versions/author",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/author/index.vue").then(m => m.default || m)
  },
  {
    name: "versionauthor___en",
    path: "/en/versions/author",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/author/index.vue").then(m => m.default || m)
  },
  {
    name: "versionauthor___fr",
    path: "/fr/versions/author",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/author/index.vue").then(m => m.default || m)
  },
  {
    name: "versionauthor___cs",
    path: "/cs/versions/author",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/author/index.vue").then(m => m.default || m)
  },
  {
    name: "versionauthor___it",
    path: "/it/versions/author",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/author/index.vue").then(m => m.default || m)
  },
  {
    name: "versionauthor___pl",
    path: "/pl/versions/author",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/author/index.vue").then(m => m.default || m)
  },
  {
    name: "versionblog-slug___de",
    path: "/de/versions/blog/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionblog-slug___en",
    path: "/en/versions/blog/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionblog-slug___fr",
    path: "/fr/versions/blog/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionblog-slug___cs",
    path: "/cs/versions/blog/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionblog-slug___it",
    path: "/it/versions/blog/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionblog-slug___pl",
    path: "/pl/versions/blog/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionbooklet-labels-design-details___de",
    path: "/de/versions/booklet-labels-design-details",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/booklet-labels-design-details.vue").then(m => m.default || m)
  },
  {
    name: "versionbooklet-labels-design-details___en",
    path: "/en/versions/booklet-labels-design-details",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/booklet-labels-design-details.vue").then(m => m.default || m)
  },
  {
    name: "versionbooklet-labels-design-details___fr",
    path: "/fr/versions/booklet-labels-design-details",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/booklet-labels-design-details.vue").then(m => m.default || m)
  },
  {
    name: "versionbooklet-labels-design-details___cs",
    path: "/cs/versions/booklet-labels-design-details",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/booklet-labels-design-details.vue").then(m => m.default || m)
  },
  {
    name: "versionbooklet-labels-design-details___it",
    path: "/it/versions/booklet-labels-design-details",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/booklet-labels-design-details.vue").then(m => m.default || m)
  },
  {
    name: "versionbooklet-labels-design-details___pl",
    path: "/pl/versions/booklet-labels-design-details",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/booklet-labels-design-details.vue").then(m => m.default || m)
  },
  {
    name: "versionbooklet-labels-form___de",
    path: "/de/versions/booklet-labels-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/booklet-labels-form.vue").then(m => m.default || m)
  },
  {
    name: "versionbooklet-labels-form___en",
    path: "/en/versions/booklet-labels-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/booklet-labels-form.vue").then(m => m.default || m)
  },
  {
    name: "versionbooklet-labels-form___fr",
    path: "/fr/versions/booklet-labels-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/booklet-labels-form.vue").then(m => m.default || m)
  },
  {
    name: "versionbooklet-labels-form___cs",
    path: "/cs/versions/booklet-labels-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/booklet-labels-form.vue").then(m => m.default || m)
  },
  {
    name: "versionbooklet-labels-form___it",
    path: "/it/versions/booklet-labels-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/booklet-labels-form.vue").then(m => m.default || m)
  },
  {
    name: "versionbooklet-labels-form___pl",
    path: "/pl/versions/booklet-labels-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/booklet-labels-form.vue").then(m => m.default || m)
  },
  {
    name: "versioncategory-slug___de",
    path: "/de/versions/category/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versioncategory-slug___en",
    path: "/en/versions/category/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versioncategory-slug___fr",
    path: "/fr/versions/category/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versioncategory-slug___cs",
    path: "/cs/versions/category/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versioncategory-slug___it",
    path: "/it/versions/category/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versioncategory-slug___pl",
    path: "/pl/versions/category/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versioncategory___de",
    path: "/de/versions/category",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: "versioncategory___en",
    path: "/en/versions/category",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: "versioncategory___fr",
    path: "/fr/versions/category",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: "versioncategory___cs",
    path: "/cs/versions/category",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: "versioncategory___it",
    path: "/it/versions/category",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: "versioncategory___pl",
    path: "/pl/versions/category",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: "versionclear-cache-all___de",
    path: "/de/versions/clear-cache-all",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/clear-cache-all.vue").then(m => m.default || m)
  },
  {
    name: "versionclear-cache-all___en",
    path: "/en/versions/clear-cache-all",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/clear-cache-all.vue").then(m => m.default || m)
  },
  {
    name: "versionclear-cache-all___fr",
    path: "/fr/versions/clear-cache-all",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/clear-cache-all.vue").then(m => m.default || m)
  },
  {
    name: "versionclear-cache-all___cs",
    path: "/cs/versions/clear-cache-all",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/clear-cache-all.vue").then(m => m.default || m)
  },
  {
    name: "versionclear-cache-all___it",
    path: "/it/versions/clear-cache-all",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/clear-cache-all.vue").then(m => m.default || m)
  },
  {
    name: "versionclear-cache-all___pl",
    path: "/pl/versions/clear-cache-all",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/clear-cache-all.vue").then(m => m.default || m)
  },
  {
    name: "versionconsultants___de",
    path: "/de/versions/consultants",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/consultants.vue").then(m => m.default || m)
  },
  {
    name: "versionconsultants___en",
    path: "/en/versions/consultants",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/consultants.vue").then(m => m.default || m)
  },
  {
    name: "versionconsultants___fr",
    path: "/fr/versions/consultants",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/consultants.vue").then(m => m.default || m)
  },
  {
    name: "versionconsultants___cs",
    path: "/cs/versions/consultants",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/consultants.vue").then(m => m.default || m)
  },
  {
    name: "versionconsultants___it",
    path: "/it/versions/consultants",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/consultants.vue").then(m => m.default || m)
  },
  {
    name: "versionconsultants___pl",
    path: "/pl/versions/consultants",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/consultants.vue").then(m => m.default || m)
  },
  {
    name: "versioncontact___de",
    path: "/de/versions/contact",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "versioncontact___en",
    path: "/en/versions/contact",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "versioncontact___fr",
    path: "/fr/versions/contact",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "versioncontact___cs",
    path: "/cs/versions/contact",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "versioncontact___it",
    path: "/it/versions/contact",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "versioncontact___pl",
    path: "/pl/versions/contact",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "versioncontent-slug___de",
    path: "/de/versions/content/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versioncontent-slug___en",
    path: "/en/versions/content/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versioncontent-slug___fr",
    path: "/fr/versions/content/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versioncontent-slug___cs",
    path: "/cs/versions/content/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versioncontent-slug___it",
    path: "/it/versions/content/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versioncontent-slug___pl",
    path: "/pl/versions/content/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versioncustom-made-shipping-boxes-form___de",
    path: "/de/versions/custom-made-shipping-boxes-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/custom-made-shipping-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: "versioncustom-made-shipping-boxes-form___en",
    path: "/en/versions/custom-made-shipping-boxes-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/custom-made-shipping-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: "versioncustom-made-shipping-boxes-form___fr",
    path: "/fr/versions/custom-made-shipping-boxes-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/custom-made-shipping-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: "versioncustom-made-shipping-boxes-form___cs",
    path: "/cs/versions/custom-made-shipping-boxes-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/custom-made-shipping-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: "versioncustom-made-shipping-boxes-form___it",
    path: "/it/versions/custom-made-shipping-boxes-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/custom-made-shipping-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: "versioncustom-made-shipping-boxes-form___pl",
    path: "/pl/versions/custom-made-shipping-boxes-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/custom-made-shipping-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: "versiondata-check-slug___de",
    path: "/de/versions/data-check/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/data-check/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versiondata-check-slug___en",
    path: "/en/versions/data-check/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/data-check/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versiondata-check-slug___fr",
    path: "/fr/versions/data-check/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/data-check/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versiondata-check-slug___cs",
    path: "/cs/versions/data-check/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/data-check/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versiondata-check-slug___it",
    path: "/it/versions/data-check/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/data-check/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versiondata-check-slug___pl",
    path: "/pl/versions/data-check/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/data-check/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versiondata-check___de",
    path: "/de/versions/data-check",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/data-check/index.vue").then(m => m.default || m)
  },
  {
    name: "versiondata-check___en",
    path: "/en/versions/data-check",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/data-check/index.vue").then(m => m.default || m)
  },
  {
    name: "versiondata-check___fr",
    path: "/fr/versions/data-check",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/data-check/index.vue").then(m => m.default || m)
  },
  {
    name: "versiondata-check___cs",
    path: "/cs/versions/data-check",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/data-check/index.vue").then(m => m.default || m)
  },
  {
    name: "versiondata-check___it",
    path: "/it/versions/data-check",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/data-check/index.vue").then(m => m.default || m)
  },
  {
    name: "versiondata-check___pl",
    path: "/pl/versions/data-check",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/data-check/index.vue").then(m => m.default || m)
  },
  {
    name: "versiondelivery___de",
    path: "/de/versions/delivery",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/delivery.vue").then(m => m.default || m)
  },
  {
    name: "versiondelivery___en",
    path: "/en/versions/delivery",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/delivery.vue").then(m => m.default || m)
  },
  {
    name: "versiondelivery___fr",
    path: "/fr/versions/delivery",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/delivery.vue").then(m => m.default || m)
  },
  {
    name: "versiondelivery___cs",
    path: "/cs/versions/delivery",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/delivery.vue").then(m => m.default || m)
  },
  {
    name: "versiondelivery___it",
    path: "/it/versions/delivery",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/delivery.vue").then(m => m.default || m)
  },
  {
    name: "versiondelivery___pl",
    path: "/pl/versions/delivery",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/delivery.vue").then(m => m.default || m)
  },
  {
    name: "versiondictionary___de",
    path: "/de/versions/dictionary",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/dictionary.vue").then(m => m.default || m)
  },
  {
    name: "versiondigibox___de",
    path: "/de/versions/digibox",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/digibox.vue").then(m => m.default || m)
  },
  {
    name: "versiondigibox___en",
    path: "/en/versions/digibox",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/digibox.vue").then(m => m.default || m)
  },
  {
    name: "versiondigibox___fr",
    path: "/fr/versions/digibox",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/digibox.vue").then(m => m.default || m)
  },
  {
    name: "versiondigibox___cs",
    path: "/cs/versions/digibox",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/digibox.vue").then(m => m.default || m)
  },
  {
    name: "versiondigibox___it",
    path: "/it/versions/digibox",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/digibox.vue").then(m => m.default || m)
  },
  {
    name: "versiondigibox___pl",
    path: "/pl/versions/digibox",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/digibox.vue").then(m => m.default || m)
  },
  {
    name: "versionfaq-slug___de",
    path: "/de/versions/faq/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionfaq-slug___en",
    path: "/en/versions/faq/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionfaq-slug___fr",
    path: "/fr/versions/faq/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionfaq-slug___cs",
    path: "/cs/versions/faq/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionfaq-slug___it",
    path: "/it/versions/faq/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionfaq-slug___pl",
    path: "/pl/versions/faq/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionfaq___de",
    path: "/de/versions/faq",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "versionfaq___en",
    path: "/en/versions/faq",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "versionfaq___fr",
    path: "/fr/versions/faq",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "versionfaq___cs",
    path: "/cs/versions/faq",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "versionfaq___it",
    path: "/it/versions/faq",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "versionfaq___pl",
    path: "/pl/versions/faq",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "versionfolding-boxes-form___de",
    path: "/de/versions/folding-boxes-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/folding-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: "versionfolding-boxes-form___en",
    path: "/en/versions/folding-boxes-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/folding-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: "versionfolding-boxes-form___fr",
    path: "/fr/versions/folding-boxes-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/folding-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: "versionfolding-boxes-form___cs",
    path: "/cs/versions/folding-boxes-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/folding-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: "versionfolding-boxes-form___it",
    path: "/it/versions/folding-boxes-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/folding-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: "versionfolding-boxes-form___pl",
    path: "/pl/versions/folding-boxes-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/folding-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: "versionfood-labels-informations___de",
    path: "/de/versions/food-labels-informations",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/food-labels-informations.vue").then(m => m.default || m)
  },
  {
    name: "versionhealthz___de",
    path: "/de/versions/healthz",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/healthz.vue").then(m => m.default || m)
  },
  {
    name: "versionhealthz___en",
    path: "/en/versions/healthz",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/healthz.vue").then(m => m.default || m)
  },
  {
    name: "versionhealthz___fr",
    path: "/fr/versions/healthz",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/healthz.vue").then(m => m.default || m)
  },
  {
    name: "versionhealthz___cs",
    path: "/cs/versions/healthz",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/healthz.vue").then(m => m.default || m)
  },
  {
    name: "versionhealthz___it",
    path: "/it/versions/healthz",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/healthz.vue").then(m => m.default || m)
  },
  {
    name: "versionhealthz___pl",
    path: "/pl/versions/healthz",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/healthz.vue").then(m => m.default || m)
  },
  {
    name: "versionimpressum___de",
    path: "/de/versions/impressum",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: "versionimpressum___en",
    path: "/en/versions/impressum",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: "versionimpressum___fr",
    path: "/fr/versions/impressum",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: "versionimpressum___cs",
    path: "/cs/versions/impressum",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: "versionimpressum___it",
    path: "/it/versions/impressum",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: "versionimpressum___pl",
    path: "/pl/versions/impressum",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: "versionindex___de",
    path: "/de/versions",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "versionindex___en",
    path: "/en/versions",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "versionindex___fr",
    path: "/fr/versions",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "versionindex___cs",
    path: "/cs/versions",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "versionindex___it",
    path: "/it/versions",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "versionindex___pl",
    path: "/pl/versions",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "versionjobs-slug___de",
    path: "/de/versions/jobs/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionjobs-slug___en",
    path: "/en/versions/jobs/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionjobs-slug___fr",
    path: "/fr/versions/jobs/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionjobs-slug___cs",
    path: "/cs/versions/jobs/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionjobs-slug___it",
    path: "/it/versions/jobs/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionjobs-slug___pl",
    path: "/pl/versions/jobs/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionjobs___de",
    path: "/de/versions/jobs",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "versionjobs___en",
    path: "/en/versions/jobs",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "versionjobs___fr",
    path: "/fr/versions/jobs",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "versionjobs___cs",
    path: "/cs/versions/jobs",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "versionjobs___it",
    path: "/it/versions/jobs",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "versionjobs___pl",
    path: "/pl/versions/jobs",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "versionlaminate-tubes-form___de",
    path: "/de/versions/laminate-tubes-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/laminate-tubes-form.vue").then(m => m.default || m)
  },
  {
    name: "versionlaminate-tubes-form___en",
    path: "/en/versions/laminate-tubes-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/laminate-tubes-form.vue").then(m => m.default || m)
  },
  {
    name: "versionlaminate-tubes-form___fr",
    path: "/fr/versions/laminate-tubes-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/laminate-tubes-form.vue").then(m => m.default || m)
  },
  {
    name: "versionlaminate-tubes-form___cs",
    path: "/cs/versions/laminate-tubes-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/laminate-tubes-form.vue").then(m => m.default || m)
  },
  {
    name: "versionlaminate-tubes-form___it",
    path: "/it/versions/laminate-tubes-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/laminate-tubes-form.vue").then(m => m.default || m)
  },
  {
    name: "versionlaminate-tubes-form___pl",
    path: "/pl/versions/laminate-tubes-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/laminate-tubes-form.vue").then(m => m.default || m)
  },
  {
    name: "versionmachines___de",
    path: "/de/versions/machines",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/machines.vue").then(m => m.default || m)
  },
  {
    name: "versionmachines___en",
    path: "/en/versions/machines",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/machines.vue").then(m => m.default || m)
  },
  {
    name: "versionmachines___fr",
    path: "/fr/versions/machines",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/machines.vue").then(m => m.default || m)
  },
  {
    name: "versionmachines___cs",
    path: "/cs/versions/machines",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/machines.vue").then(m => m.default || m)
  },
  {
    name: "versionmachines___it",
    path: "/it/versions/machines",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/machines.vue").then(m => m.default || m)
  },
  {
    name: "versionmachines___pl",
    path: "/pl/versions/machines",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/machines.vue").then(m => m.default || m)
  },
  {
    name: "versionmagazine-slug___de",
    path: "/de/versions/magazine/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/magazine/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionmagazine-slug___en",
    path: "/en/versions/magazine/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/magazine/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionmagazine-slug___fr",
    path: "/fr/versions/magazine/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/magazine/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionmagazine-slug___cs",
    path: "/cs/versions/magazine/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/magazine/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionmagazine-slug___it",
    path: "/it/versions/magazine/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/magazine/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionmagazine-slug___pl",
    path: "/pl/versions/magazine/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/magazine/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionmagazine___de",
    path: "/de/versions/magazine",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/magazine/index.vue").then(m => m.default || m)
  },
  {
    name: "versionmagazine___en",
    path: "/en/versions/magazine",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/magazine/index.vue").then(m => m.default || m)
  },
  {
    name: "versionmagazine___fr",
    path: "/fr/versions/magazine",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/magazine/index.vue").then(m => m.default || m)
  },
  {
    name: "versionmagazine___cs",
    path: "/cs/versions/magazine",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/magazine/index.vue").then(m => m.default || m)
  },
  {
    name: "versionmagazine___it",
    path: "/it/versions/magazine",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/magazine/index.vue").then(m => m.default || m)
  },
  {
    name: "versionmagazine___pl",
    path: "/pl/versions/magazine",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/magazine/index.vue").then(m => m.default || m)
  },
  {
    name: "versionmajor-customers-contact___de",
    path: "/de/versions/major-customers-contact",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/major-customers-contact.vue").then(m => m.default || m)
  },
  {
    name: "versionmajor-customers-contact___en",
    path: "/en/versions/major-customers-contact",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/major-customers-contact.vue").then(m => m.default || m)
  },
  {
    name: "versionmajor-customers-contact___fr",
    path: "/fr/versions/major-customers-contact",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/major-customers-contact.vue").then(m => m.default || m)
  },
  {
    name: "versionmajor-customers-contact___cs",
    path: "/cs/versions/major-customers-contact",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/major-customers-contact.vue").then(m => m.default || m)
  },
  {
    name: "versionmajor-customers-contact___it",
    path: "/it/versions/major-customers-contact",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/major-customers-contact.vue").then(m => m.default || m)
  },
  {
    name: "versionmajor-customers-contact___pl",
    path: "/pl/versions/major-customers-contact",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/major-customers-contact.vue").then(m => m.default || m)
  },
  {
    name: "versionmajor-customers___de",
    path: "/de/versions/major-customers",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/major-customers.vue").then(m => m.default || m)
  },
  {
    name: "versionmajor-customers___en",
    path: "/en/versions/major-customers",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/major-customers.vue").then(m => m.default || m)
  },
  {
    name: "versionmajor-customers___fr",
    path: "/fr/versions/major-customers",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/major-customers.vue").then(m => m.default || m)
  },
  {
    name: "versionmajor-customers___cs",
    path: "/cs/versions/major-customers",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/major-customers.vue").then(m => m.default || m)
  },
  {
    name: "versionmajor-customers___it",
    path: "/it/versions/major-customers",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/major-customers.vue").then(m => m.default || m)
  },
  {
    name: "versionmajor-customers___pl",
    path: "/pl/versions/major-customers",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/major-customers.vue").then(m => m.default || m)
  },
  {
    name: "versionmaterials-slug___de",
    path: "/de/versions/materials/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/materials/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionmaterials-slug___en",
    path: "/en/versions/materials/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/materials/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionmaterials-slug___fr",
    path: "/fr/versions/materials/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/materials/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionmaterials-slug___cs",
    path: "/cs/versions/materials/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/materials/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionmaterials-slug___it",
    path: "/it/versions/materials/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/materials/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionmaterials-slug___pl",
    path: "/pl/versions/materials/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/materials/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionmaterials___de",
    path: "/de/versions/materials",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/materials/index.vue").then(m => m.default || m)
  },
  {
    name: "versionmaterials___en",
    path: "/en/versions/materials",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/materials/index.vue").then(m => m.default || m)
  },
  {
    name: "versionmaterials___fr",
    path: "/fr/versions/materials",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/materials/index.vue").then(m => m.default || m)
  },
  {
    name: "versionmaterials___cs",
    path: "/cs/versions/materials",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/materials/index.vue").then(m => m.default || m)
  },
  {
    name: "versionmaterials___it",
    path: "/it/versions/materials",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/materials/index.vue").then(m => m.default || m)
  },
  {
    name: "versionmaterials___pl",
    path: "/pl/versions/materials",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/materials/index.vue").then(m => m.default || m)
  },
  {
    name: "versionmellerud___de",
    path: "/de/versions/mellerud",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/mellerud.vue").then(m => m.default || m)
  },
  {
    name: "versionmellerud___en",
    path: "/en/versions/mellerud",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/mellerud.vue").then(m => m.default || m)
  },
  {
    name: "versionmellerud___fr",
    path: "/fr/versions/mellerud",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/mellerud.vue").then(m => m.default || m)
  },
  {
    name: "versionmellerud___cs",
    path: "/cs/versions/mellerud",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/mellerud.vue").then(m => m.default || m)
  },
  {
    name: "versionmellerud___it",
    path: "/it/versions/mellerud",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/mellerud.vue").then(m => m.default || m)
  },
  {
    name: "versionmellerud___pl",
    path: "/pl/versions/mellerud",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/mellerud.vue").then(m => m.default || m)
  },
  {
    name: "versionmymuesli___de",
    path: "/de/versions/mymuesli",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/mymuesli.vue").then(m => m.default || m)
  },
  {
    name: "versionmymuesli___en",
    path: "/en/versions/mymuesli",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/mymuesli.vue").then(m => m.default || m)
  },
  {
    name: "versionmymuesli___fr",
    path: "/fr/versions/mymuesli",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/mymuesli.vue").then(m => m.default || m)
  },
  {
    name: "versionmymuesli___cs",
    path: "/cs/versions/mymuesli",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/mymuesli.vue").then(m => m.default || m)
  },
  {
    name: "versionmymuesli___it",
    path: "/it/versions/mymuesli",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/mymuesli.vue").then(m => m.default || m)
  },
  {
    name: "versionmymuesli___pl",
    path: "/pl/versions/mymuesli",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/mymuesli.vue").then(m => m.default || m)
  },
  {
    name: "versionmymusli___de",
    path: "/de/versions/mymusli",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/mymusli.vue").then(m => m.default || m)
  },
  {
    name: "versionmymusli___en",
    path: "/en/versions/mymusli",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/mymusli.vue").then(m => m.default || m)
  },
  {
    name: "versionmymusli___fr",
    path: "/fr/versions/mymusli",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/mymusli.vue").then(m => m.default || m)
  },
  {
    name: "versionmymusli___cs",
    path: "/cs/versions/mymusli",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/mymusli.vue").then(m => m.default || m)
  },
  {
    name: "versionmymusli___it",
    path: "/it/versions/mymusli",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/mymusli.vue").then(m => m.default || m)
  },
  {
    name: "versionmymusli___pl",
    path: "/pl/versions/mymusli",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/mymusli.vue").then(m => m.default || m)
  },
  {
    name: "versionnewsletter-confirmation___de",
    path: "/de/versions/newsletter-confirmation",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/newsletter-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "versionnewsletter-confirmation___en",
    path: "/en/versions/newsletter-confirmation",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/newsletter-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "versionnewsletter-confirmation___fr",
    path: "/fr/versions/newsletter-confirmation",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/newsletter-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "versionnewsletter-confirmation___cs",
    path: "/cs/versions/newsletter-confirmation",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/newsletter-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "versionnewsletter-confirmation___it",
    path: "/it/versions/newsletter-confirmation",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/newsletter-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "versionnewsletter-confirmation___pl",
    path: "/pl/versions/newsletter-confirmation",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/newsletter-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-address___de",
    path: "/de/versions/order/address",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/address.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-address___en",
    path: "/en/versions/order/address",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/address.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-address___fr",
    path: "/fr/versions/order/address",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/address.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-address___cs",
    path: "/cs/versions/order/address",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/address.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-address___it",
    path: "/it/versions/order/address",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/address.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-address___pl",
    path: "/pl/versions/order/address",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/address.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-card___de",
    path: "/de/versions/order/card",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/card.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-card___en",
    path: "/en/versions/order/card",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/card.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-card___fr",
    path: "/fr/versions/order/card",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/card.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-card___cs",
    path: "/cs/versions/order/card",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/card.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-card___it",
    path: "/it/versions/order/card",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/card.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-card___pl",
    path: "/pl/versions/order/card",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/card.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-login___de",
    path: "/de/versions/order/login",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/login.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-login___en",
    path: "/en/versions/order/login",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/login.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-login___fr",
    path: "/fr/versions/order/login",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/login.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-login___cs",
    path: "/cs/versions/order/login",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/login.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-login___it",
    path: "/it/versions/order/login",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/login.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-login___pl",
    path: "/pl/versions/order/login",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/login.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-overview___de",
    path: "/de/versions/order/overview",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/overview.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-overview___en",
    path: "/en/versions/order/overview",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/overview.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-overview___fr",
    path: "/fr/versions/order/overview",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/overview.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-overview___cs",
    path: "/cs/versions/order/overview",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/overview.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-overview___it",
    path: "/it/versions/order/overview",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/overview.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-overview___pl",
    path: "/pl/versions/order/overview",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/overview.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-printdata___de",
    path: "/de/versions/order/printdata",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/printdata.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-printdata___en",
    path: "/en/versions/order/printdata",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/printdata.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-printdata___fr",
    path: "/fr/versions/order/printdata",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/printdata.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-printdata___cs",
    path: "/cs/versions/order/printdata",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/printdata.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-printdata___it",
    path: "/it/versions/order/printdata",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/printdata.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-printdata___pl",
    path: "/pl/versions/order/printdata",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/printdata.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-shipping___de",
    path: "/de/versions/order/shipping",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/shipping.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-shipping___en",
    path: "/en/versions/order/shipping",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/shipping.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-shipping___fr",
    path: "/fr/versions/order/shipping",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/shipping.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-shipping___cs",
    path: "/cs/versions/order/shipping",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/shipping.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-shipping___it",
    path: "/it/versions/order/shipping",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/shipping.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-shipping___pl",
    path: "/pl/versions/order/shipping",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/shipping.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-thank-you___de",
    path: "/de/versions/order/thank-you",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-thank-you___en",
    path: "/en/versions/order/thank-you",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-thank-you___fr",
    path: "/fr/versions/order/thank-you",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-thank-you___cs",
    path: "/cs/versions/order/thank-you",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-thank-you___it",
    path: "/it/versions/order/thank-you",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "versionorder-thank-you___pl",
    path: "/pl/versions/order/thank-you",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/order/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "versionour-promise___de",
    path: "/de/versions/our-promise",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/our-promise.vue").then(m => m.default || m)
  },
  {
    name: "versionour-promise___en",
    path: "/en/versions/our-promise",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/our-promise.vue").then(m => m.default || m)
  },
  {
    name: "versionour-promise___fr",
    path: "/fr/versions/our-promise",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/our-promise.vue").then(m => m.default || m)
  },
  {
    name: "versionour-promise___cs",
    path: "/cs/versions/our-promise",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/our-promise.vue").then(m => m.default || m)
  },
  {
    name: "versionour-promise___it",
    path: "/it/versions/our-promise",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/our-promise.vue").then(m => m.default || m)
  },
  {
    name: "versionour-promise___pl",
    path: "/pl/versions/our-promise",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/our-promise.vue").then(m => m.default || m)
  },
  {
    name: "versionpackage-leaflets-package-inserts-form___de",
    path: "/de/versions/package-leaflets-package-inserts-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/package-leaflets-package-inserts-form.vue").then(m => m.default || m)
  },
  {
    name: "versionpackage-leaflets-package-inserts-form___en",
    path: "/en/versions/package-leaflets-package-inserts-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/package-leaflets-package-inserts-form.vue").then(m => m.default || m)
  },
  {
    name: "versionpackage-leaflets-package-inserts-form___fr",
    path: "/fr/versions/package-leaflets-package-inserts-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/package-leaflets-package-inserts-form.vue").then(m => m.default || m)
  },
  {
    name: "versionpackage-leaflets-package-inserts-form___cs",
    path: "/cs/versions/package-leaflets-package-inserts-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/package-leaflets-package-inserts-form.vue").then(m => m.default || m)
  },
  {
    name: "versionpackage-leaflets-package-inserts-form___it",
    path: "/it/versions/package-leaflets-package-inserts-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/package-leaflets-package-inserts-form.vue").then(m => m.default || m)
  },
  {
    name: "versionpackage-leaflets-package-inserts-form___pl",
    path: "/pl/versions/package-leaflets-package-inserts-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/package-leaflets-package-inserts-form.vue").then(m => m.default || m)
  },
  {
    name: "versionpaypal-paypal_listener___de",
    path: "/de/versions/paypal/paypal_listener",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/paypal/paypal_listener.vue").then(m => m.default || m)
  },
  {
    name: "versionpaypal-paypal_listener___en",
    path: "/en/versions/paypal/paypal_listener",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/paypal/paypal_listener.vue").then(m => m.default || m)
  },
  {
    name: "versionpaypal-paypal_listener___fr",
    path: "/fr/versions/paypal/paypal_listener",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/paypal/paypal_listener.vue").then(m => m.default || m)
  },
  {
    name: "versionpaypal-paypal_listener___cs",
    path: "/cs/versions/paypal/paypal_listener",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/paypal/paypal_listener.vue").then(m => m.default || m)
  },
  {
    name: "versionpaypal-paypal_listener___it",
    path: "/it/versions/paypal/paypal_listener",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/paypal/paypal_listener.vue").then(m => m.default || m)
  },
  {
    name: "versionpaypal-paypal_listener___pl",
    path: "/pl/versions/paypal/paypal_listener",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/paypal/paypal_listener.vue").then(m => m.default || m)
  },
  {
    name: "versionprint-data___de",
    path: "/de/versions/print-data",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/print-data/index.vue").then(m => m.default || m)
  },
  {
    name: "versionprint-data___en",
    path: "/en/versions/print-data",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/print-data/index.vue").then(m => m.default || m)
  },
  {
    name: "versionprint-data___fr",
    path: "/fr/versions/print-data",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/print-data/index.vue").then(m => m.default || m)
  },
  {
    name: "versionprint-data___cs",
    path: "/cs/versions/print-data",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/print-data/index.vue").then(m => m.default || m)
  },
  {
    name: "versionprint-data___it",
    path: "/it/versions/print-data",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/print-data/index.vue").then(m => m.default || m)
  },
  {
    name: "versionprint-data___pl",
    path: "/pl/versions/print-data",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/print-data/index.vue").then(m => m.default || m)
  },
  {
    name: "versionprint-data-repair___de",
    path: "/de/versions/print-data/repair",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/print-data/repair.vue").then(m => m.default || m)
  },
  {
    name: "versionprint-data-repair___en",
    path: "/en/versions/print-data/repair",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/print-data/repair.vue").then(m => m.default || m)
  },
  {
    name: "versionprint-data-repair___fr",
    path: "/fr/versions/print-data/repair",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/print-data/repair.vue").then(m => m.default || m)
  },
  {
    name: "versionprint-data-repair___cs",
    path: "/cs/versions/print-data/repair",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/print-data/repair.vue").then(m => m.default || m)
  },
  {
    name: "versionprint-data-repair___it",
    path: "/it/versions/print-data/repair",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/print-data/repair.vue").then(m => m.default || m)
  },
  {
    name: "versionprint-data-repair___pl",
    path: "/pl/versions/print-data/repair",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/print-data/repair.vue").then(m => m.default || m)
  },
  {
    name: "versionprinted-labels-form___de",
    path: "/de/versions/printed-labels-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/printed-labels-form.vue").then(m => m.default || m)
  },
  {
    name: "versionprinted-labels-form___en",
    path: "/en/versions/printed-labels-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/printed-labels-form.vue").then(m => m.default || m)
  },
  {
    name: "versionprinted-labels-form___fr",
    path: "/fr/versions/printed-labels-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/printed-labels-form.vue").then(m => m.default || m)
  },
  {
    name: "versionprinted-labels-form___cs",
    path: "/cs/versions/printed-labels-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/printed-labels-form.vue").then(m => m.default || m)
  },
  {
    name: "versionprinted-labels-form___it",
    path: "/it/versions/printed-labels-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/printed-labels-form.vue").then(m => m.default || m)
  },
  {
    name: "versionprinted-labels-form___pl",
    path: "/pl/versions/printed-labels-form",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/printed-labels-form.vue").then(m => m.default || m)
  },
  {
    name: "versionprivacy-policy___de",
    path: "/de/versions/privacy-policy",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "versionprivacy-policy___en",
    path: "/en/versions/privacy-policy",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "versionprivacy-policy___fr",
    path: "/fr/versions/privacy-policy",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "versionprivacy-policy___cs",
    path: "/cs/versions/privacy-policy",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "versionprivacy-policy___it",
    path: "/it/versions/privacy-policy",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "versionprivacy-policy___pl",
    path: "/pl/versions/privacy-policy",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "versionproducts-slug___de",
    path: "/de/versions/products/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionproducts-slug___en",
    path: "/en/versions/products/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionproducts-slug___fr",
    path: "/fr/versions/products/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionproducts-slug___cs",
    path: "/cs/versions/products/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionproducts-slug___it",
    path: "/it/versions/products/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionproducts-slug___pl",
    path: "/pl/versions/products/:slug()",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: "versionproducts___de",
    path: "/de/versions/products",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "versionproducts___en",
    path: "/en/versions/products",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "versionproducts___fr",
    path: "/fr/versions/products",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "versionproducts___cs",
    path: "/cs/versions/products",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "versionproducts___it",
    path: "/it/versions/products",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "versionproducts___pl",
    path: "/pl/versions/products",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "versionredirect-to-paypal___de",
    path: "/de/versions/redirect-to-paypal",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/redirect-to-paypal.vue").then(m => m.default || m)
  },
  {
    name: "versionredirect-to-paypal___en",
    path: "/en/versions/redirect-to-paypal",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/redirect-to-paypal.vue").then(m => m.default || m)
  },
  {
    name: "versionredirect-to-paypal___fr",
    path: "/fr/versions/redirect-to-paypal",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/redirect-to-paypal.vue").then(m => m.default || m)
  },
  {
    name: "versionredirect-to-paypal___cs",
    path: "/cs/versions/redirect-to-paypal",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/redirect-to-paypal.vue").then(m => m.default || m)
  },
  {
    name: "versionredirect-to-paypal___it",
    path: "/it/versions/redirect-to-paypal",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/redirect-to-paypal.vue").then(m => m.default || m)
  },
  {
    name: "versionredirect-to-paypal___pl",
    path: "/pl/versions/redirect-to-paypal",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/redirect-to-paypal.vue").then(m => m.default || m)
  },
  {
    name: "versionsample-corrugated-cardboard___de",
    path: "/de/versions/sample-corrugated-cardboard",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sample-corrugated-cardboard.vue").then(m => m.default || m)
  },
  {
    name: "versionsample-corrugated-cardboard___en",
    path: "/en/versions/sample-corrugated-cardboard",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sample-corrugated-cardboard.vue").then(m => m.default || m)
  },
  {
    name: "versionsample-corrugated-cardboard___fr",
    path: "/fr/versions/sample-corrugated-cardboard",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sample-corrugated-cardboard.vue").then(m => m.default || m)
  },
  {
    name: "versionsample-corrugated-cardboard___cs",
    path: "/cs/versions/sample-corrugated-cardboard",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sample-corrugated-cardboard.vue").then(m => m.default || m)
  },
  {
    name: "versionsample-corrugated-cardboard___it",
    path: "/it/versions/sample-corrugated-cardboard",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sample-corrugated-cardboard.vue").then(m => m.default || m)
  },
  {
    name: "versionsample-corrugated-cardboard___pl",
    path: "/pl/versions/sample-corrugated-cardboard",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sample-corrugated-cardboard.vue").then(m => m.default || m)
  },
  {
    name: "versionsample___de",
    path: "/de/versions/sample",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sample.vue").then(m => m.default || m)
  },
  {
    name: "versionsample___en",
    path: "/en/versions/sample",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sample.vue").then(m => m.default || m)
  },
  {
    name: "versionsample___fr",
    path: "/fr/versions/sample",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sample.vue").then(m => m.default || m)
  },
  {
    name: "versionsample___cs",
    path: "/cs/versions/sample",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sample.vue").then(m => m.default || m)
  },
  {
    name: "versionsample___it",
    path: "/it/versions/sample",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sample.vue").then(m => m.default || m)
  },
  {
    name: "versionsample___pl",
    path: "/pl/versions/sample",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sample.vue").then(m => m.default || m)
  },
  {
    name: "versionsampletube___de",
    path: "/de/versions/sampletube",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sampletube.vue").then(m => m.default || m)
  },
  {
    name: "versionsampletube___en",
    path: "/en/versions/sampletube",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sampletube.vue").then(m => m.default || m)
  },
  {
    name: "versionsampletube___fr",
    path: "/fr/versions/sampletube",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sampletube.vue").then(m => m.default || m)
  },
  {
    name: "versionsampletube___cs",
    path: "/cs/versions/sampletube",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sampletube.vue").then(m => m.default || m)
  },
  {
    name: "versionsampletube___it",
    path: "/it/versions/sampletube",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sampletube.vue").then(m => m.default || m)
  },
  {
    name: "versionsampletube___pl",
    path: "/pl/versions/sampletube",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sampletube.vue").then(m => m.default || m)
  },
  {
    name: "versionsitemap___de",
    path: "/de/versions/sitemap",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "versionsitemap___en",
    path: "/en/versions/sitemap",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "versionsitemap___fr",
    path: "/fr/versions/sitemap",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "versionsitemap___cs",
    path: "/cs/versions/sitemap",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "versionsitemap___it",
    path: "/it/versions/sitemap",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "versionsitemap___pl",
    path: "/pl/versions/sitemap",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "versionsustainability___de",
    path: "/de/versions/sustainability",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: "versionsustainability___en",
    path: "/en/versions/sustainability",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: "versionsustainability___fr",
    path: "/fr/versions/sustainability",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: "versionsustainability___cs",
    path: "/cs/versions/sustainability",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: "versionsustainability___it",
    path: "/it/versions/sustainability",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: "versionsustainability___pl",
    path: "/pl/versions/sustainability",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: "versionswiss-fair___de",
    path: "/de/versions/swiss-fair",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/swiss-fair.vue").then(m => m.default || m)
  },
  {
    name: "versionswiss-fair___en",
    path: "/en/versions/swiss-fair",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/swiss-fair.vue").then(m => m.default || m)
  },
  {
    name: "versionswiss-fair___fr",
    path: "/fr/versions/swiss-fair",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/swiss-fair.vue").then(m => m.default || m)
  },
  {
    name: "versionswiss-fair___cs",
    path: "/cs/versions/swiss-fair",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/swiss-fair.vue").then(m => m.default || m)
  },
  {
    name: "versionswiss-fair___it",
    path: "/it/versions/swiss-fair",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/swiss-fair.vue").then(m => m.default || m)
  },
  {
    name: "versionswiss-fair___pl",
    path: "/pl/versions/swiss-fair",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/swiss-fair.vue").then(m => m.default || m)
  },
  {
    name: "versionterms-of-service___de",
    path: "/de/versions/terms-of-service",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "versionterms-of-service___en",
    path: "/en/versions/terms-of-service",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "versionterms-of-service___fr",
    path: "/fr/versions/terms-of-service",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "versionterms-of-service___cs",
    path: "/cs/versions/terms-of-service",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "versionterms-of-service___it",
    path: "/it/versions/terms-of-service",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "versionterms-of-service___pl",
    path: "/pl/versions/terms-of-service",
    meta: {"robots":{"content":"noindex, nofollow"}},
    component: () => import("/usr/src/webshop/pages/terms-of-service.vue").then(m => m.default || m)
  }
]