
// @ts-nocheck


export const localeCodes =  [
  "de",
  "en",
  "fr",
  "cs",
  "it",
  "pl"
]

export const localeLoaders = {
  "de": [{ key: "../lang/de.json", load: () => import("../lang/de.json" /* webpackChunkName: "locale__usr_src_webshop_lang_de_json" */), cache: true }],
  "en": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__usr_src_webshop_lang_en_json" */), cache: true }],
  "fr": [{ key: "../lang/fr.json", load: () => import("../lang/fr.json" /* webpackChunkName: "locale__usr_src_webshop_lang_fr_json" */), cache: true }],
  "cs": [{ key: "../lang/cs.json", load: () => import("../lang/cs.json" /* webpackChunkName: "locale__usr_src_webshop_lang_cs_json" */), cache: true }],
  "it": [{ key: "../lang/it.json", load: () => import("../lang/it.json" /* webpackChunkName: "locale__usr_src_webshop_lang_it_json" */), cache: true }],
  "pl": [{ key: "../lang/pl.json", load: () => import("../lang/pl.json" /* webpackChunkName: "locale__usr_src_webshop_lang_pl_json" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "de",
      "iso": "de-DE",
      "countryCode": "DE",
      "currency": "EUR",
      "files": [
        "lang/de.json"
      ]
    },
    {
      "code": "en",
      "iso": "en-UK",
      "countryCode": "UK",
      "currency": "GBP",
      "files": [
        "lang/en.json"
      ]
    },
    {
      "code": "fr",
      "iso": "fr-FR",
      "countryCode": "FR",
      "currency": "EUR",
      "files": [
        "lang/fr.json"
      ]
    },
    {
      "code": "cs",
      "iso": "cs-CZ",
      "countryCode": "CZ",
      "currency": "CZK",
      "files": [
        "lang/cs.json"
      ]
    },
    {
      "code": "it",
      "iso": "it-IT",
      "countryCode": "IT",
      "currency": "EUR",
      "files": [
        "lang/it.json"
      ]
    },
    {
      "code": "pl",
      "iso": "pl-PL",
      "countryCode": "PL",
      "currency": "PLN",
      "files": [
        "lang/pl.json"
      ]
    }
  ],
  "defaultLocale": "de",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "lang",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "http://placeholder-app.com",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "de",
    "iso": "de-DE",
    "countryCode": "DE",
    "currency": "EUR",
    "files": [
      {
        "path": "lang/de.json"
      }
    ]
  },
  {
    "code": "en",
    "iso": "en-UK",
    "countryCode": "UK",
    "currency": "GBP",
    "files": [
      {
        "path": "lang/en.json"
      }
    ]
  },
  {
    "code": "fr",
    "iso": "fr-FR",
    "countryCode": "FR",
    "currency": "EUR",
    "files": [
      {
        "path": "lang/fr.json"
      }
    ]
  },
  {
    "code": "cs",
    "iso": "cs-CZ",
    "countryCode": "CZ",
    "currency": "CZK",
    "files": [
      {
        "path": "lang/cs.json"
      }
    ]
  },
  {
    "code": "it",
    "iso": "it-IT",
    "countryCode": "IT",
    "currency": "EUR",
    "files": [
      {
        "path": "lang/it.json"
      }
    ]
  },
  {
    "code": "pl",
    "iso": "pl-PL",
    "countryCode": "PL",
    "currency": "PLN",
    "files": [
      {
        "path": "lang/pl.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
